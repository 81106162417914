import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { calculateDateDistance, formatDate } from '../utils/helper'
import Responses from './Responses'
import { SocketContext } from '../context/SocketProvider'
import { HiOutlineTemplate } from 'react-icons/hi'

const Messages = ({ chat_id, chatDetails, chatDetailsSetter, messages, setMessages, isEnterpriseAdmin, pageEnded, setPageEnded, viewingSharedChat = false, tokens, updateTokens, setShowAdModal, setShowMaxInteractionsReachedModal }) => {
    const [user] = useContext(UserContext)
    const [mySocket] = useContext(SocketContext)

    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)

    const loadMoreDivRef = useRef(null)

    useEffect(() => {
        if (mySocket) {
            mySocket.on('message_added', (data) => {
                if (data.created_by !== user.user_id && pageEnded) {
                    setMessages(prevMessages => [...prevMessages, data.new_message])
                }
            })
        }
    }, [pageEnded])

    useEffect(() => {
        fetchMessages()
    }, [])

    const fetchMessages = (_load = true) => {
        if (_load) {
            setLoading(true)
        }
        setPageEnded(false)
        ProtectedAxios.post("/users/messages", { chat_id, viewingSharedChat, page: 1 })
            .then(res => {
                setMessages(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log("err in fetching messages - ", err);
                setLoading(false)
            })
    }

    let myPage = 1
    const loadMoreMessages = () => {
        setLoadingMore(true)
        myPage++
        ProtectedAxios.post("/users/messages", { chat_id, viewingSharedChat, page: myPage })
            .then(res => {
                if (res.data.length < 4 || res.data.length === 0) {
                    setPageEnded(true)
                }
                setMessages(prev => [...prev, ...res.data])
                // let createdAt = new Date().toISOString()
                // setMessages(prev => [...prev, { chat_id, created_by: user.user_id, profile_picture_src: user.profile_picture_src, message_content: "This is a temp test message from Arib", username: user.username, name: user.name, created_at: createdAt }])

                setLoadingMore(false)
            })
            .catch(err => {
                console.log("err in fetching messages - ", err);
                setLoadingMore(false)
            })
    }


    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                loadMoreMessages()
            }
        });
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        const loadMoreButton = loadMoreDivRef.current;

        if (loadMoreButton) {
            observer.observe(loadMoreButton);
        }

        return () => {
            if (loadMoreButton) {
                observer.unobserve(loadMoreButton);
            }
        };
    }, [loading]);

    return (
        <div className='message-container container'>
            {loading
                ?
                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '50vh' }}>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>

                :
                <>
                    <div className='chat-info'>
                        <span>
                            {chatDetails.created_by === user.user_id ? "You" : chatDetails.name} started this chat
                        </span>
                    </div>

                    <div className='messages'>
                        {messages.map((message, i) => {
                            return (
                                <div className={`message ${message.created_by === user.user_id ? "self-message" : ""}`} key={i} id={`message-${message.created_at}`}>
                                    <div className='message-header'>
                                        {message.created_by !== user.user_id
                                            &&
                                            <div>
                                                <div className='profile-pic chat-profile-pic' style={{ backgroundImage: `url(${message.profile_picture_src ? `${message.profile_picture_src}` : placeholderProfile})` }} />
                                            </div>
                                        }
                                        <div className='message-body'>
                                            <div className='message-content'>
                                                {message.username !== user.username
                                                    &&
                                                    <span className='message-by'>{message.name}</span>
                                                }
                                                <p className='m-0'>
                                                    {
                                                        chatDetails?.template_ref?.template_id && i === 0
                                                            ? chatDetails?.template_ref?.type === "CHAT_STARTER_PRIVATE"
                                                                ?
                                                                <div className='chat-select justify-content-end w-100' title={chatDetails.template_ref.description}>
                                                                    <div className='d-flex align-items-center gap-2 chat-select-item fw-medium' style={{ backgroundColor: "#22222220" }}>
                                                                        <HiOutlineTemplate className='' />
                                                                        <p className='m-0 font-us fw-medium'>started with template - {chatDetails.template_ref.name}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='d-flex flex-column gap-3'>
                                                                    {message.message_content}
                                                                    <div className='chat-select justify-content-end w-100 mb-2' title={chatDetails.template_ref.description}>
                                                                        <div className='d-flex align-items-center gap-2 chat-select-item fw-medium' style={{ backgroundColor: "#22222220" }}>
                                                                            <HiOutlineTemplate className='' />
                                                                            <p className='m-0 font-us fw-medium'>started with template - {chatDetails.template_ref.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : message.message_content
                                                    }

                                                    {/* {
                                                        chatDetails?.template_ref?.template_id && chatDetails?.template_ref?.type === "CHAT_STARTER_PRIVATE" && i === 0
                                                            ?
                                                            <>
                                                                <div className='chat-select justify-content-end w-100' title={chatDetails.template_ref.description}>
                                                                    <div className='d-flex align-items-center gap-2 chat-select-item fw-medium' style={{ backgroundColor: "#22222220" }}>
                                                                        <HiOutlineTemplate className='' />
                                                                        <p className='m-0 font-us fw-medium'>started with template - {chatDetails.template_ref.name}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                                ? chatDetails?.template_ref?.template_id && (chatDetails?.template_ref?.type === "NORMAL" || chatDetails?.template_ref?.type === "CHAT_STARTER_OPEN") && i === 0
                                                                    ? "ABC"
                                                                    : message.message_content
                                                    } */}
                                                </p>
                                                <span className='message-at'>{calculateDateDistance(new Date(message.created_at))}</span>
                                            </div>
                                            {/* <p className='message-details'> */}
                                            {/* {message.username === user.username ? "You" : message.name} */}
                                            {/* , &nbsp; */}
                                            {/* {calculateDateDistance(new Date(message.created_at))} */}
                                            {/* </p> */}
                                        </div>
                                    </div>
                                    {message.message_id
                                        &&
                                        <Responses
                                            chat_id={chat_id}
                                            chatDetails={chatDetails}
                                            chatDetailsSetter={chatDetailsSetter}
                                            message_id={message.message_id}
                                            isEnterpriseAdmin={isEnterpriseAdmin}
                                            tokens={tokens}
                                            updateTokens={updateTokens}
                                            setMessages={setMessages}
                                            setShowAdModal={setShowAdModal}
                                            setShowMaxInteractionsReachedModal={setShowMaxInteractionsReachedModal}
                                            lastResponse={pageEnded && i + 1 === messages.length}
                                        />
                                    }
                                </div>
                            )
                        })}
                        {!pageEnded
                            ?
                            loadingMore
                                ?
                                <div className='d-flex justify-content-center my-4 py-4'>
                                    <div className="spinner-border spinner-border-md" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                                :
                                <div ref={loadMoreDivRef}></div>
                            : ""
                        }


                    </div>
                </>
            }

        </div>
    )
}

export default Messages