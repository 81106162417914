import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider';
import { useNavigate } from 'react-router-dom'
import { HiArrowDown, HiArrowRight } from 'react-icons/hi'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import UpgradeSucceeded from './UpgradeSucceeded';
import UpgradeCancelled from './UpgradeFailed';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

const UpgradeSubscription = () => {
    const navigate = useNavigate()

    const [user, setUser] = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [selectedPriceId, setSelectedPriceId] = useState('')

    const [currentPlan, setCurrentPlan] = useState()
    const [upgradeOptions, setUpgradeOptions] = useState([])

    const [upgradeStatus, setUpgradeStatus] = useState(0)


    useEffect(() => {
        fetchUpgradeDetail()
    }, [])

    const fetchUpgradeDetail = () => {
        setLoading(true)
        ProtectedAxios.post('/subscription/upgradeDetails', { subscription_id: user.stripe_subscription_id, customer_id: user.stripe_customer_id })
            .then(res => {
                setLoading(false)
                if (res.data) {
                    setCurrentPlan(res.data.currentPlan)

                    const upgradeOptions = res.data.upgradeOptions
                    upgradeOptions.sort((a, b) => a.amount - b.amount)
                    setUpgradeOptions(upgradeOptions)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const getPlanName = _currentPriceId => {
        if (_currentPriceId === process.env.REACT_APP_PRICE_A_ID) {
            return 'Basic'
        }
        else if (_currentPriceId === process.env.REACT_APP_PRICE_B_ID) {
            return 'Advanced'
        }
        else {
            return 'Expert'

        }
    }
    const getPlanFeatures = _currentPriceId => {
        if (_currentPriceId === process.env.REACT_APP_PRICE_A_ID) {
            return <>
                <p className='feature'>1 prompt</p>
                <p className='feature'>10 sub prompt</p>
            </>
        }
        else if (_currentPriceId === process.env.REACT_APP_PRICE_B_ID) {
            return <>
                <p className='feature'>300 prompt <span className='sub-text'>/month</span></p>
                <p className='feature'>20 sub prompt <span className='sub-text'>/month</span></p>
            </>
        }
        else {
            return <>
                <p className='feature'>Unlimited prompt <span className='sub-text'>/month</span></p>
                <p className='feature'>Unlimited sub prompt <span className='sub-text'>/month</span></p>
            </>

        }
    }

    const upgradeSubscription = _priceId => {
        setSelectedPriceId(_priceId)
        setProcessing(true)

        if (currentPlan.amount === 0) {
            const toastId = toast.loading('Redirecting to payment gateway...')
            ProtectedAxios.post('/subscription/upgrade-plan-from-free', { price_id: _priceId, customer_id: user.stripe_customer_id })
                .then(res => {
                    window.location.href = res.data
                    setProcessing(false)
                    toast.remove(toastId)
                })
                .catch(err => {
                    setProcessing(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    }
                })
        } else {
            ProtectedAxios.post('/subscription/upgrade-plan', { price_id: _priceId, customer_id: user.stripe_customer_id })
                .then(res => {
                    if (res.data) {
                        setUpgradeStatus(1)
                        setProcessing(false)
                    }
                })
                .catch(err => {
                    setUpgradeStatus(-1)
                    setProcessing(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    }
                })
        }
    }

    const [activeTab, setActiveTab] = useState(1)


    return (
        <>
            <Helmet>
                <title>Upgrade - SageCollab</title>
            </Helmet>

            {upgradeStatus === 0
                ?
                <div className='pb-5 subscription-main'>
                    <h2>Upgrade Subscription</h2>
                    <p className='subtitle'>Unlock More Features with an Upgraded Subscription</p>
                    {loading
                        ?
                        <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                            <div className="spinner-border spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>

                        :
                        <>
                            <div className='subscription-container subscription-container-lg'>
                                <div className='current-subscription'>
                                    <div className='subscription subscription1'>
                                        <div className='subscription-header'>
                                            <div className='right'>
                                                <p className='name'>{currentPlan.plan.metadata.plan_name}</p>
                                                <p className='price'>{currentPlan.plan.currency.toUpperCase()} ${currentPlan.amount} <span className='sub-text'>{currentPlan.plan.unit_amount > 0 && '/month'}</span></p>
                                            </div>
                                        </div>
                                        <div className='subscription-body'>
                                            <div className='features'>
                                                {JSON.parse(currentPlan.plan.metadata.offerings).map((offer, x) => {
                                                    return (
                                                        <p className='feature' key={x}>{offer.offering}</p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='subscription-footer'>

                                        </div>
                                    </div>
                                </div>

                                <div className='subscription-upgrade-options'>
                                    {upgradeOptions.length === 0
                                        ?
                                        <p className='no-upgrades-message'>No upgrade options available, on highest subscription</p>

                                        :
                                        <>
                                            {upgradeOptions.map((plan, i) => {
                                                return (
                                                    <div className='subscription recommended' key={i}>
                                                        <div className='subscription-header'>
                                                            <div className='right'>
                                                                <p className='name'>{plan.plan.metadata.plan_name}</p>
                                                                <p className='price'>{plan.plan.currency.toUpperCase()} ${plan.plan.unit_amount / 100} <div className='sub-text'>{plan.plan.unit_amount > 0 && '/month'}</div></p>
                                                            </div>
                                                        </div>
                                                        <div className='subscription-body'>
                                                            <div className='features'>
                                                                <>
                                                                    {JSON.parse(plan.plan.metadata.offerings).map((offer, x) => {
                                                                        return (
                                                                            <p className='feature' key={x}>{offer.offering}</p>
                                                                        )
                                                                    })}
                                                                </>
                                                                {currentPlan.amount !== 0
                                                                    &&

                                                                    <p className='feature info-feature'>
                                                                        Only SGD ${(plan.proratedPrice - currentPlan.proratedPrice).toFixed(2)} for remaining {currentPlan.remainingDays} days
                                                                        <OverlayTrigger
                                                                            delay={{ hide: 450, show: 300 }}
                                                                            overlay={(props) => (
                                                                                <Tooltip {...props}>
                                                                                    <br />
                                                                                    <div className='tooltip-item-container'>
                                                                                        <h6>Upgrade Cost</h6>
                                                                                    </div>
                                                                                    <div className='tooltip-item-container'>
                                                                                        <div className='tooltip-item-left'>current plan unused for {currentPlan.remainingDays} day(s)</div>
                                                                                        <div className='seperator'>:</div>
                                                                                        <div className='tooltip-item-right'>-${currentPlan.proratedPrice}</div>
                                                                                    </div>
                                                                                    <br />
                                                                                    {/* <hr /> */}
                                                                                    <div className='tooltip-item-container'>
                                                                                        <div className='tooltip-item-left'>new plan charge for remaining {currentPlan.remainingDays} day(s)</div>
                                                                                        <div className='seperator'>:</div>
                                                                                        <div className='tooltip-item-right'>+${plan.proratedPrice}</div>
                                                                                    </div>
                                                                                    <hr />
                                                                                    <div className='tooltip-item-container'>
                                                                                        <div className='tooltip-item-left'>total upgrade cost</div>
                                                                                        <div className='seperator'>:</div>
                                                                                        <div className='tooltip-item-right'>${(parseFloat(plan.proratedPrice) - parseFloat(currentPlan.proratedPrice)).toFixed(2)}</div>
                                                                                    </div>
                                                                                    <br />
                                                                                </Tooltip>
                                                                            )}
                                                                            placement="right"
                                                                        >
                                                                            <button className='tooltip-button'><BsFillInfoCircleFill /></button>
                                                                        </OverlayTrigger>
                                                                    </p>
                                                                }
                                                                <p className='feature info-feature'>Subscribe at {plan.plan.currency.toUpperCase()} ${plan.amount}/month</p>
                                                            </div>
                                                        </div>
                                                        <div className='subscription-footer'>
                                                            <button type="submit" className="button button-main" disabled={processing} onClick={() => upgradeSubscription(plan.plan.id)}>
                                                                {processing
                                                                    ?
                                                                    <>
                                                                        Upgrade Plan
                                                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                    </>

                                                                    : "Upgrade Plan"
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>

                                    }
                                </div>

                            </div>

                            <div className='subscription-container-mobile'>
                                <div className='template-tabs-container'>
                                    <div className='template-tabs container mt-4'>
                                        <div className={`template-tab ${activeTab === 0 ? "active" : ""}`} onClick={() => { setActiveTab(0) }}>Current plan</div>
                                        <div className={`template-tab ${activeTab === 1 ? "active" : ""}`} onClick={() => { setActiveTab(1) }}>Upgrade</div>
                                    </div>
                                    <span />
                                </div>
                                {/* <div className='render-area container'> */}
                                <div className='subscription-container'>
                                    {activeTab === 0
                                        ?
                                        <div className='current-subscription'>
                                            <div className='subscription subscription1'>
                                                <div className='subscription-header'>
                                                    <div className='right'>
                                                        <p className='name'>{currentPlan.plan.metadata.plan_name}</p>
                                                        <p className='price'>{currentPlan.plan.currency.toUpperCase()} ${currentPlan.amount} <span className='sub-text'>{currentPlan.plan.unit_amount > 0 && '/month'}</span></p>
                                                    </div>
                                                </div>
                                                <div className='subscription-body'>
                                                    <div className='features'>
                                                        {JSON.parse(currentPlan.plan.metadata.offerings).map((offer, x) => {
                                                            return (
                                                                <p className='feature' key={x}>{offer.offering}</p>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className='subscription-footer'>

                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <div className='subscription-upgrade-options'>
                                            {upgradeOptions.length === 0
                                                ?
                                                <p className='no-upgrades-message'>No upgrade options available, on highest subscription</p>

                                                :
                                                <>
                                                    {upgradeOptions.map((plan, i) => {
                                                        return (
                                                            <div className='subscription recommended' key={i}>
                                                                <div className='subscription-header'>
                                                                    <div className='right'>
                                                                        <p className='name'>{plan.plan.metadata.plan_name}</p>
                                                                        <p className='price'>{plan.plan.currency.toUpperCase()} ${plan.plan.unit_amount / 100} <span className='sub-text'>{plan.plan.unit_amount > 0 && '/month'}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className='subscription-body'>
                                                                    <div className='features'>
                                                                        <>
                                                                            {JSON.parse(plan.plan.metadata.offerings).map((offer, x) => {
                                                                                return (
                                                                                    <p className='feature' key={x}>{offer.offering}</p>
                                                                                )
                                                                            })}
                                                                        </>
                                                                        {currentPlan.amount !== 0
                                                                            &&

                                                                            <p className='feature info-feature'>
                                                                                Only SGD ${(plan.proratedPrice - currentPlan.proratedPrice).toFixed(2)} for remaining {currentPlan.remainingDays} days
                                                                                <OverlayTrigger
                                                                                    delay={{ hide: 450, show: 300 }}
                                                                                    overlay={(props) => (
                                                                                        <Tooltip {...props}>
                                                                                            <br />
                                                                                            <div className='tooltip-item-container'>
                                                                                                <h6>Upgrade Cost</h6>
                                                                                            </div>
                                                                                            <div className='tooltip-item-container'>
                                                                                                <div className='tooltip-item-left'>current plan unused for {currentPlan.remainingDays} day(s)</div>
                                                                                                <div className='seperator'>:</div>
                                                                                                <div className='tooltip-item-right'>-${currentPlan.proratedPrice}</div>
                                                                                            </div>
                                                                                            <br />
                                                                                            {/* <hr /> */}
                                                                                            <div className='tooltip-item-container'>
                                                                                                <div className='tooltip-item-left'>new plan charge for remaining {currentPlan.remainingDays} day(s)</div>
                                                                                                <div className='seperator'>:</div>
                                                                                                <div className='tooltip-item-right'>+${plan.proratedPrice}</div>
                                                                                            </div>
                                                                                            <hr />
                                                                                            <div className='tooltip-item-container'>
                                                                                                <div className='tooltip-item-left'>total upgrade cost</div>
                                                                                                <div className='seperator'>:</div>
                                                                                                <div className='tooltip-item-right'>${(parseFloat(plan.proratedPrice) - parseFloat(currentPlan.proratedPrice)).toFixed(2)}</div>
                                                                                            </div>
                                                                                            <br />
                                                                                        </Tooltip>
                                                                                    )}
                                                                                    placement="right"
                                                                                >
                                                                                    <button className='tooltip-button'><BsFillInfoCircleFill /></button>
                                                                                </OverlayTrigger>
                                                                            </p>
                                                                        }
                                                                        <p className='feature info-feature'>Subscribe at {plan.plan.currency.toUpperCase()} ${plan.amount}/month</p>
                                                                    </div>
                                                                </div>
                                                                <div className='subscription-footer'>
                                                                    <button type="submit" className="button button-main" disabled={processing} onClick={() => upgradeSubscription(plan.plan.id)}>
                                                                        {processing
                                                                            ?
                                                                            <>
                                                                                Upgrade Plan
                                                                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                            </>

                                                                            : "Upgrade Plan"
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </>

                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div >

                :
                <>
                    {upgradeStatus === 1
                        ?
                        <UpgradeSucceeded />

                        :
                        <UpgradeCancelled />
                    }
                </>
            }
        </>
    )
}

export default UpgradeSubscription