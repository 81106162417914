import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'

const useSubscriptionDetails = () => {
    const [user, setUser] = useContext(UserContext)
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)

    useEffect(() => {
        if (user.stripe_customer_id && user.user_id !== user.stripe_customer_id) {
            fetchSubscriptionDetails()
        }
    }, [user])

    const fetchSubscriptionDetails = () => {
        ProtectedAxios.post('/subscription/subscribedPlanDetails', { customer_id: user.stripe_customer_id, user_id: user.user_id })
            .then(res => {
                setSubscriptionDetails(res.data)

            })
            .catch(err => {
                console.log(err);
            })
    }

    const refreshSubscriptionDetails = () => {
        fetchSubscriptionDetails()
    }

    return [subscriptionDetails, refreshSubscriptionDetails]
}

export default useSubscriptionDetails