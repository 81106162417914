import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import ProtectedAxios from "../../api/protectedAxios";
import toast from "react-hot-toast";
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import MyDropdown from "../../components/MyDropdown";
import { TbArrowsSort } from "react-icons/tb";

const HighlightedTemplateCategories = () => {
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [categories, setCategories] = useState([]);
    const [backupCategories, setBackupCategories] = useState([]);
    const [highlightingCategory, setHighlightingCategory] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [viewingHighlightedOnly, setViewingHighlightedOnly] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [updatingPriorityIndex, setUpdatingPriorityIndex] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState("CATEGORY_ASC")
    useEffect(() => {
        fetchCategories()
    }, [])
    const fetchCategories = () => {
        setLoadingCategories(true);
        ProtectedAxios.get('/admin/templateCategories')
            .then(res => {
                setBackupCategories(res.data);
                setCategories(res.data);
                setLoadingCategories(false);
            })
            .catch(err => {
                toast.error(err.response.data.error);
                setLoadingCategories(false);
            })
    }

    const toggleCategoryHighlight = (_category) => {
        setHighlightingCategory(true)
        ProtectedAxios.post('/admin/templateCategories/highlight/toggle', { selectedCategory: _category })
            .then(res => {
                setCategories(prev => {
                    const _categories = [...prev];
                    const selectedCategoryIndex = _categories.findIndex(category => category.category_id === _category.category_id);
                    _categories[selectedCategoryIndex].highlighted = !_category.highlighted;
                    return _categories
                })
                setHighlightingCategory(false)
            })
            .catch(err => {
                toast.error(err.response.data.error)
                setHighlightingCategory(false)
            })
    }

    useEffect(() => {
        setCategories(() => {
            return backupCategories.filter(category => category.category.toLowerCase().includes(searchText.toLowerCase()))
        })
    }, [searchText])

    useEffect(() => {
        toggleSorting();
    }, [selectedSortOption]);
    const toggleSorting = () => {
        setCategories(prev => {
            const updatedCategories = [...prev];

            if (selectedSortOption === "INDEX_DESC") {
                updatedCategories.sort((a, b) => b.highlight_priority_index - a.highlight_priority_index);
            } else if (selectedSortOption === "INDEX_ASC") {
                updatedCategories.sort((a, b) => a.highlight_priority_index - b.highlight_priority_index);
            } else if (selectedSortOption === "CATEGORY_ASC") {
                updatedCategories.sort((a, b) => a.category.localeCompare(b.category));
            } else if (selectedSortOption === "CATEGORY_DESC") {
                updatedCategories.sort((a, b) => b.category.localeCompare(a.category));
            }

            return updatedCategories;
        });
    }

    const toggleHighlightedOnlyFilter = () => {
        if (!viewingHighlightedOnly) {
            setCategories(() => {
                return backupCategories.filter(category => category.highlighted === true && category.category.toLowerCase().includes(searchText.toLowerCase()));
            })
        } else {
            setCategories(() => {
                return backupCategories.filter(category => category.category.toLowerCase().includes(searchText.toLowerCase()))
            })
        }
        setViewingHighlightedOnly(prev => !prev);
    }

    const updateCategoryPriorityIndex = (_category) => {
        setUpdatingPriorityIndex(true)
        setSelectedCategory(_category)
        ProtectedAxios.post('/admin/templateCategories/priorityIndex/update', { updatedIndex: _category.highlight_priority_index, selected_category_id: _category.category_id })
            .then(res => {
                setUpdatingPriorityIndex(false)
                setBackupCategories(prev => {
                    const _categories = [...prev];
                    const selectedCategoryIndex = _categories.findIndex(category => category.category_id === _category.category_id);
                    _categories[selectedCategoryIndex].highlight_priority_index = _category.highlight_priority_index;
                    return _categories
                })
                setSelectedCategory(null)
            })
            .catch(err => {
                toast.error(err.response.data.error)
                setSelectedCategory(null)
                setUpdatingPriorityIndex(false)
            })
    }

    const discardCategoryPriorityChanges = (_category) => {
        setCategories(prev => {
            const _categories = [...prev];
            const selectedCategoryIndex = _categories.findIndex(category => category.category_id === _category.category_id);
            const originalCategoryIndex = backupCategories.findIndex(category => category.category_id === _category.category_id);
            const originalCategory = backupCategories[originalCategoryIndex];
            _categories[selectedCategoryIndex].highlight_priority_index = originalCategory.highlight_priority_index;
            return _categories;
        })
    }

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Highlighted Categories - SageCollab</title>
            </Helmet>

            <h2>Highlighted Categories</h2>

            <div className="d-flex flex-column text-secondary mt-3">
                <span className="">- Categories with higher priority index will appear first.</span>
                <span className="">- For categories sharing same priority index, priority will be given by name.</span>
            </div>

            <div className="mt-5 pt-3 d-flex gap-3 align-items-center">
                <input placeholder="search categories" value={searchText} onChange={e => setSearchText(e.target.value)} />
                <div className="d-flex align-items-center gap-1">
                    <button title={viewingHighlightedOnly ? 'view all' : 'only highlighted'} className={`edit-btn button-favourite-filter-toggle ${viewingHighlightedOnly ? 'on' : ''}`} onClick={toggleHighlightedOnlyFilter}>
                        {viewingHighlightedOnly
                            ? <AiFillStar className='edit-icon w-l h-l' style={{ color: 'var(--color-golden)' }} />
                            : <AiOutlineStar className='edit-icon w-l h-l' style={{ color: 'var(--color-dark)' }} />
                        }
                    </button>
                    <MyDropdown
                        disabled={backupCategories.length === 0}
                        position='left'
                        dropdownButton={
                            <div className='dropdown-link sort-dropdown dropdown'>
                                <button
                                    disabled={backupCategories.length === 0}
                                    className='dropdown-toggle gap-1'
                                >
                                    <TbArrowsSort className="w-s h-s mx-2" />
                                    {selectedSortOption === "INDEX_DESC"
                                        ? "Priority (Desc)"
                                        :
                                        selectedSortOption === "INDEX_ASC"
                                            ? "Priority (Asc)"
                                            : selectedSortOption === "CATEGORY_ASC"
                                                ? "Category (Asc)"
                                                : selectedSortOption === "CATEGORY_DESC"
                                                && "Category (Desc)"

                                    }
                                </button>
                            </div>
                        }
                        title="Sort Categories"
                    >
                        <div className={`dropdown-item ${selectedSortOption === "INDEX_ASC" ? "active" : ""}`} onClick={() => { setSelectedSortOption("INDEX_ASC") }}>Priority (Asc)</div>
                        <div className={`dropdown-item ${selectedSortOption === "INDEX_DESC" ? "active" : ""}`} onClick={() => { setSelectedSortOption("INDEX_DESC") }}>Priority (Desc)</div>
                        <div className={`dropdown-item ${selectedSortOption === "CATEGORY_ASC" ? "active" : ""}`} onClick={() => { setSelectedSortOption("CATEGORY_ASC") }}>Category (Asc)</div>
                        <div className={`dropdown-item ${selectedSortOption === "CATEGORY_DESC" ? "active" : ""}`} onClick={() => { setSelectedSortOption("CATEGORY_DESC") }}>Category (Desc)</div>
                    </MyDropdown>
                </div>
            </div>
            {/* {JSON.stringify(backupCategories[0].highlight_priority_index)}
            {JSON.stringify(categories[0].highlight_priority_index)} */}
            <div className="d-flex gap-4 flex-wrap my-5">
                {loadingCategories
                    ?
                    [1, 2, 3, 4, 5, 6].map(i => (
                        <LoadingSkeleton type="highlighted-category" />
                    ))

                    :
                    categories.map((category, i) => (
                        <div key={i} className="border rounded px-4 py-3">
                            <div className="d-flex justify-content-between align-items-center gap-5">
                                <p className="fs-6 fw-medium m-0">{category.category}</p>
                                <button title={category.highlighted ? 'remove highlight' : 'highlight'} className={`edit-btn button-favourite-filter-toggle ${category.highlighted ? 'on' : ''}`} disabled={highlightingCategory} onClick={() => { toggleCategoryHighlight(category) }}>
                                    {category.highlighted
                                        ? <AiFillStar className='edit-icon w-l h-l' style={{ color: 'var(--color-golden)' }} />
                                        : <AiOutlineStar className='edit-icon w-l h-l' style={{ color: 'var(--color-dark)' }} />
                                    }
                                </button>
                            </div>
                            <p className="text-secondary">used in <span className="fw-medium">{category.TemplateCategories.length}</span> template</p>

                            <div className="mt-4">
                                <span className="font-xxs text-secondary">Priority Index</span>
                                <div className="d-flex gap-3 align-items-center">
                                    <input
                                        type="number"
                                        value={category.highlight_priority_index}
                                        onChange={e => setCategories(prev => {
                                            const updatedCategories = [...prev];

                                            const updatedCategory = { ...updatedCategories[i] };
                                            updatedCategory.highlight_priority_index = e.target.value;
                                            updatedCategories[i] = updatedCategory;

                                            return updatedCategories;
                                        })}
                                        onBlur={e => {
                                            if (e.target.value === "") {
                                                setCategories(prev => {
                                                    const updatedCategories = [...prev];

                                                    const updatedCategory = { ...updatedCategories[i] };
                                                    updatedCategory.highlight_priority_index = 0;
                                                    updatedCategories[i] = updatedCategory;

                                                    return updatedCategories;
                                                })
                                            }
                                        }}
                                        className="h-xxl"
                                        style={{ width: "4rem" }}
                                    />
                                    <div className="d-flex align-items-center gap-1">
                                        {Number(backupCategories.filter(cat => cat.category_id === category.category_id)[0].highlight_priority_index) !== Number(category.highlight_priority_index)
                                            &&
                                            <>
                                                {updatingPriorityIndex
                                                    ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                    :
                                                    <>
                                                        <button className={`edit-btn`} title="discard" onClick={() => { discardCategoryPriorityChanges(category) }}>
                                                            <RiCloseLine className='edit-icon reject-icon w-m h-m' />
                                                        </button>
                                                        <button className={`edit-btn`} title="update" onClick={() => { updateCategoryPriorityIndex(category) }}>
                                                            <RiCheckLine className='edit-icon accept-icon w-m h-m' />
                                                        </button>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default HighlightedTemplateCategories