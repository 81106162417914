import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import ProtectedAxios from "../../api/protectedAxios";
import toast from "react-hot-toast";
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import MyDropdown from "../../components/MyDropdown";
import { TbArrowsSort } from "react-icons/tb";
import { NavLink } from "react-router-dom"
import { BiUpArrowAlt } from "react-icons/bi"
import { IoChatboxOutline, IoEyeOutline } from "react-icons/io5"
import { PiDotOutlineFill } from "react-icons/pi"

const HighlightedTemplates = () => {
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [backupTemplates, setBackupTemplates] = useState([]);
    const [highlightingCategory, setHighlightingCategory] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [viewingHighlightedOnly, setViewingHighlightedOnly] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [updatingPriorityIndex, setUpdatingPriorityIndex] = useState(false);

    useEffect(() => {
        fetchMarketplaceTemplates()
    }, [])
    const fetchMarketplaceTemplates = () => {
        setLoadingTemplates(true);
        ProtectedAxios.post("/users/fetchMarketPlaceTemplates")
            .then(res => {
                setBackupTemplates(res.data);
                setTemplates(res.data);
                setLoadingTemplates(false);
            })
            .catch(err => {
                toast.error(err.response.data.error);
                setLoadingTemplates(false);
            })
    }

    useEffect(() => {
        setTemplates(() => {
            return backupTemplates.filter(template => template.name.toLowerCase().includes(searchText.toLowerCase()))
        })
    }, [searchText])

    const updateTemplatePriorityIndex = (_template) => {
        setUpdatingPriorityIndex(true)
        setSelectedTemplate(_template)
        ProtectedAxios.post('/admin/template/priorityIndex/update', { updatedIndex: _template.priority_index, selected_template_id: _template.template_id })
            .then(res => {
                setUpdatingPriorityIndex(false)
                setBackupTemplates(prev => {
                    const _templates = [...prev];
                    const selectedTemplateIndex = _templates.findIndex(template => template.template_id === _template.template_id);
                    _templates[selectedTemplateIndex].priority_index = _template.priority_index;
                    return _templates
                })
                setSelectedTemplate(null)
            })
            .catch(err => {
                toast.error(err.response.data.error)
                setSelectedTemplate(null)
                setUpdatingPriorityIndex(false)
            })
    }

    const discardTemplatePriorityChanges = (_template) => {
        setTemplates(prev => {
            const _templates = [...prev];
            const selectedTemplateIndex = _templates.findIndex(template => template.template_id === _template.template_id);
            const originalTemplateIndex = backupTemplates.findIndex(template => template.template_id === _template.template_id);
            const originalTemplate = backupTemplates[originalTemplateIndex];
            _templates[selectedTemplateIndex].priority_index = originalTemplate.priority_index;
            return _templates;
        })
    }

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Highlighted templates - SageCollab</title>
            </Helmet>

            <h2>Highlighted templates</h2>

            <div className="d-flex flex-column text-secondary mt-3">
                <span className="">- Templates with higher priority index will appear first.</span>
                <span className="">- For templates sharing same priority index, priority will be given by creation date.</span>
            </div>

            <div className="mt-5 pt-3 d-flex gap-3 align-items-center">
                <input placeholder="search templates" value={searchText} onChange={e => setSearchText(e.target.value)} />
            </div>

            <div className="d-flex gap-4 flex-wrap my-5">
                {loadingTemplates
                    ?
                    [1, 2, 3, 4, 5, 6].map(i => (
                        <LoadingSkeleton type="highlighted-template" />
                    ))

                    :
                    templates.map((template, i) => (
                        <div key={i} className="border rounded px-4 pt-5 pb-5 pe-5 d-flex flex-column justify-content-between">
                            <div>
                                <div className="d-flex justify-content-between align-items-center gap-5">
                                    <p className="fs-6 fw-medium m-0">{template.name}</p>
                                    <div />
                                </div>
                                <div className="company-container gap-4 mt-2">
                                    <p className="company-name">
                                        {/* By&nbsp; */}
                                        {(template.created_user_role_id === 1 || template?.company_url)
                                            ?
                                            <a href={template.company_url} target="_blank">{template.company_name}</a>

                                            :
                                            <div className="d-flex align-items-center gap-1 cursor-pointer">
                                                <img src={template.created_user_profile_picture_src} alt="profile" className="profile-image w-m h-m" />
                                                <NavLink to={`/user/${template.created_user_username}`} className="m-0">{template.created_user_username}</NavLink>
                                            </div>
                                        }
                                    </p>
                                    {/* <PiDotOutlineFill style={{ fill: "var(--color-light)" }} /> */}
                                    <div className="d-flex align-items-center gap-2">
                                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1">
                                            <BiUpArrowAlt />
                                            {template?.download_count?.toLocaleString()}
                                        </div>
                                        <PiDotOutlineFill style={{ fill: "var(--color-light)" }} />
                                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1">
                                            <IoEyeOutline />
                                            {template?.views?.toLocaleString()}
                                        </div>
                                        <PiDotOutlineFill style={{ fill: "var(--color-light)" }} />
                                        <div style={{ fontSize: "var(--fs-5)" }} className="d-flex justify-content-center align-items-center gap-1">
                                            <IoChatboxOutline />
                                            {template?.used_count?.toLocaleString()}
                                        </div>
                                    </div>
                                </div>

                                {template.selectedCategories.length
                                    ?
                                    <div className="template-categories mt-3">
                                        {template.selectedCategories.map((category, i) => {
                                            return (
                                                <>
                                                    <div key={i} className={`category`}>
                                                        {category.category}
                                                    </div>
                                                    &nbsp;
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                    : ""
                                }
                            </div>

                            <div className="mt-5">
                                <span className="font-xxs text-secondary">Priority Index</span>
                                <div className="d-flex gap-3 align-items-center">
                                    <input
                                        type="number"
                                        value={template.priority_index}
                                        onChange={e => setTemplates(prev => {
                                            const updatedCategories = [...prev];

                                            const updatedCategory = { ...updatedCategories[i] };
                                            updatedCategory.priority_index = e.target.value;
                                            updatedCategories[i] = updatedCategory;

                                            return updatedCategories;
                                        })}
                                        onBlur={e => {
                                            if (e.target.value === "") {
                                                setTemplates(prev => {
                                                    const updatedCategories = [...prev];

                                                    const updatedCategory = { ...updatedCategories[i] };
                                                    updatedCategory.priority_index = 0;
                                                    updatedCategories[i] = updatedCategory;

                                                    return updatedCategories;
                                                })
                                            }
                                        }}
                                        className="h-xxl"
                                        style={{ width: "4rem" }}
                                    />
                                    <div className="d-flex align-items-center gap-1">
                                        {Number(backupTemplates.filter(_template => _template.template_id === template.template_id)[0].priority_index) !== Number(template.priority_index)
                                            &&
                                            <>
                                                {updatingPriorityIndex
                                                    ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                    :
                                                    <>
                                                        <button className={`edit-btn w-xxl h-xxl`} title="discard" onClick={() => { discardTemplatePriorityChanges(template) }}>
                                                            <RiCloseLine className='edit-icon reject-icon w-m h-m' />
                                                        </button>
                                                        <button className={`edit-btn w-xxl h-xxl`} title="update" onClick={() => { updateTemplatePriorityIndex(template) }}>
                                                            <RiCheckLine className='edit-icon accept-icon w-m h-m' />
                                                        </button>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default HighlightedTemplates