import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import ProtectedAxios from '../api/protectedAxios'
import { toast } from 'react-hot-toast'
import { BsChatLeftTextFill, BsFillTagsFill } from 'react-icons/bs'
import { FaNotesMedical, FaStickyNote, FaUserCheck } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { Helmet } from 'react-helmet'


const DashboardAdmin = () => {
  const [user, setUser] = useContext(UserContext)
  let mounted = false
  const [stats, setStats] = useState([])
  const [loadingStats, setLoadingStats] = useState(true)

  let userStatsMounted = false
  const [userStats, setUserStats] = useState([])
  const [loadingUserStats, setLoadingUserStats] = useState(true)

  useEffect(() => {
    fetchAdminStats()
    fetchUserStats()
  }, [])

  const fetchAdminStats = (user) => {
    if (!mounted) {
      mounted = true
      setLoadingStats(true)
      ProtectedAxios.get('/admin/adminStats')
        .then(res => {
          setStats(res.data)
          setLoadingStats(false)
        })
        .catch(err => {
          setLoadingStats(false)
          if (err.response.status === 500) {
            toast.error(err.response.data.error)
          } else {
            console.log(err);
          }
        })
    }
  }
  const fetchUserStats = (user) => {
    if (!userStatsMounted) {
      userStatsMounted = true
      setLoadingUserStats(true)
      ProtectedAxios.get('/admin/userStats')
        .then(res => {
          setUserStats(res.data)
          setLoadingUserStats(false)
        })
        .catch(err => {
          setLoadingUserStats(false)
          if (err.response.status === 500) {
            toast.error(err.response.data.error)
          } else {
            console.log(err);
          }
        })
    }
  }


  return (
    <div className='container py-5'>
      <Helmet>
        <title>Dashboard - SageCollab</title>
      </Helmet>

      <h2>Welcome, Admin</h2>

      <div className='stat-container'>
        <div className='stat'>
          <div className='stat-header'>
            <span>Chats</span>
            <BsChatLeftTextFill />
          </div>
          <div className='stat-body'>
            {loadingStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(stats.total_chat).toLocaleString()}
              </p>
            }
          </div>
        </div>

        <div className='stat stat-sec'>
          <div className='stat-header'>
            <span>Tags</span>
            <BsFillTagsFill />
          </div>
          <div className='stat-body'>
            {loadingStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(stats.tags).toLocaleString()}
              </p>
            }
          </div>
        </div>

        <div className='stat stat-tert'>
          <div className='stat-header'>
            <span>Shared Chats</span>
            <FaUserCheck />
          </div>
          <div className='stat-body'>
            {loadingStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(stats.shared_chats).toLocaleString()}
              </p>
            }
          </div>
        </div>

        <div className='stat stat-tert'>
          <div className='stat-header'>
            <span>Notes</span>
            <FaStickyNote />
          </div>
          <div className='stat-body'>
            {loadingStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(stats.total_notes).toLocaleString()}
              </p>
            }
          </div>
        </div>
      </div>

      <div className='user-stats-container'>
        <div className='total-users-container'>
          <div className='total-users'>
            <HiUserGroup />
            {loadingStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <>
                <p>{stats.total_users}</p>
                <span>Total Users</span>
              </>
            }
          </div>
        </div>

        <div className='divided-users'>
          <div className='user-type'>
            {loadingUserStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(userStats.freeUsers).toLocaleString()}
              </p>
            }
            <span>Free</span>
          </div>
          <div className='user-type'>
            {loadingUserStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(userStats.standardUsers).toLocaleString()}
              </p>
            }
            <span>Standard</span>
          </div>

          <div className='user-type'>
            {loadingUserStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(userStats.enterpriseAdmin).toLocaleString()}
              </p>
            }
            <span>Enterprise</span>
          </div>

          <div className='user-type'>
            {loadingUserStats
              ?
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>

              :
              <p>
                {parseInt(userStats.enterpriseChild).toLocaleString()}
              </p>
            }
            <span>Enterprise Child</span>
          </div>

        </div>
      </div>
    </div>
  )
}

export default DashboardAdmin