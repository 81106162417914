import { useEffect } from 'react';
import Axios from '../api/axios';

const TemplateViewManager = ({template_id, setTemplateDetails}) => {
    useEffect(() => {
        recordTemplateView()
    }, [template_id])

    const recordTemplateView = () => {
        if(template_id){
            Axios.post(`/users/template/view/record/${template_id}`)
            .then(res => {
                if(res.data.view_incremented){
                    setTemplateDetails(prev => {return {...prev, views: prev.views + 1}})
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }
}

export default TemplateViewManager;
