import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { toast } from 'react-hot-toast'
import Modal from 'react-bootstrap/Modal';
import { formatDate } from '../utils/helper';
import { MdSearch } from 'react-icons/md';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { GrFormClose } from 'react-icons/gr';
import { BiFilter, BiCategoryAlt } from 'react-icons/bi';
import MyDropdown from '../components/MyDropdown';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { RiChatUploadLine } from 'react-icons/ri';

const SharedTemplates = () => {
  const [user] = useContext(UserContext)

  const [loadingTemplates, setLoadingTemplates] = useState(true)
  const [templates, setTemplates] = useState([])

  const [templateSearchText, setTemplateSearchText] = useState('')
  const [searchingWithText, setSearchingWithText] = useState(false)
  const [searchText, setSearchText] = useState(false)

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [loadingSelectedTemplateStructure, setLoadingSelectedTemplateStructure] = useState(false)
  const [selectedTemplateStructure, setSelectedTemplateStructure] = useState([])
  const [deletingTemplate, setDeletingTemplate] = useState(false)

  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    fetchTemplates()
  }, [])

  useEffect(() => {
    if (templateSearchText.length === 0) {
      fetchTemplates()
    }
  }, [templateSearchText])

  const fetchTemplates = async (e, _selectedCategories, _filterType) => {
    if (e) {
      e?.preventDefault()
    }

    setCategoryDropdownVisible(false)
    setLoadingTemplates(true)

    let selected_categories = _selectedCategories ? _selectedCategories : selectedFilterCategories
    if (templateSearchText.length > 0) {
      setSearchingWithText(true)
      setSearchText(templateSearchText)
    } else {
      setSearchingWithText(false)
    }

    ProtectedAxios.post('/users/fetchSharedTemplates', { user_id: user.user_id, templateSearchText: templateSearchText, selectedCategories: selected_categories })
      .then(res => {
        if (res.data) {
          setTemplates(res.data)
          setLoadingTemplates(false)
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("could not fetch your templates at the moment, please try again later.")
        setLoadingTemplates(false)
      })
  }



  const [selectedFilterCategories, setSelectedFilterCategories] = useState([])
  const [fetchingTemplateCategories, setFetchingTemplateCategories] = useState(false)
  const [templateCategories, setTemplateCategories] = useState([])
  const [backupTemplateCategories, setBackupTemplateCategories] = useState([])
  const [searchTemplateCategoryText, setSearchTemplateCategoryText] = useState("")

  useEffect(() => {
    if (categoryDropdownVisible) {
      fetchTemplateCategories()
    }
  }, [categoryDropdownVisible])

  const fetchTemplateCategories = () => {
    setFetchingTemplateCategories(true)
    ProtectedAxios.post("/users/fetchSharedTemplateCategories", { user_id: user.user_id, selectedCategories: selectedFilterCategories })
      .then(res => {
        setTemplateCategories(res.data)
        setBackupTemplateCategories(res.data)
        setFetchingTemplateCategories(false)
      })
      .catch(err => {
        console.log(err)
        toast.error("Could not fetch categories at the moment please try again later.")
        setFetchingTemplateCategories(false)
      })
  }

  const selectTemplateCategory = (_category) => {
    setSearchTemplateCategoryText("")
    setSelectedFilterCategories(prev => {
      let updatedCategories = [...prev]
      updatedCategories.push(_category)
      fetchTemplates(undefined, updatedCategories)
      return updatedCategories
    })
  }

  const removeTemplateCategory = (_category) => {
    const filteredCategories = selectedFilterCategories.filter(category => category.category_id !== _category.category_id)
    setSelectedFilterCategories(filteredCategories)
    fetchTemplates(undefined, filteredCategories)
  }

  useEffect(() => {
    filterTemplateCategoryList()
  }, [searchTemplateCategoryText])
  const filterTemplateCategoryList = () => {
    if (searchTemplateCategoryText.length > 0) {
      const updatedList = backupTemplateCategories.filter(category => {
        return category.category.toLowerCase().includes(searchTemplateCategoryText.toLowerCase())
      })
      setTemplateCategories(updatedList)
    } else {
      setTemplateCategories(backupTemplateCategories)
    }
  }




  const [confirmDeleteSharedTemplateModal, setConfirmDeleteSharedTemplateModal] = useState(false)
  const closeConfirmDeleteSharedTemplateModal = async () => { setConfirmDeleteSharedTemplateModal(false) }
  const openConfirmDeleteSharedTemplateModal = () => setConfirmDeleteSharedTemplateModal(true)

  const removeTemplateFromSharedList = async (e) => {
    e.preventDefault()
    setDeletingTemplate(true)
    ProtectedAxios.post('/users/templates/removeTemplateFromSharedList', { user_id: user.user_id, template_id: selectedTemplate?.template_id })
      .then(res => {
        if (res.data) {
          setTemplates(prev => {
            let currentTemplates = [...prev]
            let updatedTemplates = currentTemplates.filter(template => template.template_id !== selectedTemplate?.template_id)
            return updatedTemplates
          })
          setDeletingTemplate(false)
          closeConfirmDeleteSharedTemplateModal()
          toast.success(`Template "${selectedTemplate?.name.substring(0, 15)}${selectedTemplate?.name.length > 15 && "..."}" removed from your shared list`)
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("could not delete template at the moment, please try again later.")
        setDeletingTemplate(false)
      })

  }


  const categoryFilterDropdownRef = useRef(null)
  useClickOutside(categoryFilterDropdownRef)
  function useClickOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCategoryDropdownVisible(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }



  const [viewTemplateModal, setViewTemplateModal] = useState(false)
  const closeViewTemplateModal = () => { setViewTemplateModal(false) }
  const openViewTemplateModal = () => { setViewTemplateModal(true) }
  const renderSelectedTemplatePreview = (templateStructure, templateStructureSetter) => {
    return <div className='form-container'>
      {
        templateStructure.map((element, i) => {
          return (
            <div className={``} key={i} id={`element-item-${element.id}`}>

              {element.type === "text"
                &&
                <>
                  <p>{templateStructure[i].content}</p>
                </>
              }

              {element.type === "hidden-text"
                &&
                <div className='text-secondary'>
                  <span className='font-us'>This element will be hidden in template</span>
                  <p>{templateStructure[i].content}</p>
                </div>
              }

              {element.type === "textarea"
                &&
                <>
                  <label for={`input-${element.id}`} className='label font-xxs'>{templateStructure[i].label}</label>
                  <input
                    id={`input-${element.id}`}
                    value={templateStructure[i]?.value}
                    onChange={e => {
                      templateStructureSetter(prev => {
                        let updatedData = [...prev]
                        updatedData[i].value = e.target.value
                        return updatedData
                      })
                    }}
                  />
                </>
              }

              {element.type === "select"
                &&
                <>
                  <label for={`input-${element.id}`} className='label font-xxs'>{element.label}</label>
                  <select
                    className='form-select'
                    id={`input-${element.id}`}
                    value={templateStructure[i]?.value}
                    onChange={e => {
                      templateStructureSetter(prev => {
                        let updatedData = [...prev]
                        updatedData[i].value = e.target.value
                        return updatedData
                      })
                    }}
                  >
                    {element.options.map((option, option_key) => {
                      return (
                        <option
                          key={option_key}
                          value={option.value}
                        >
                          {option.key}
                        </option>
                      )
                    })}
                  </select>
                </>
              }

            </div>
          )
        })
      }
    </div>
  }

  const setTemplateStructure = async (template_id, functionToExecute) => {
    setLoadingSelectedTemplateStructure(true)
    toast.loading("Please wait", { id: "load-prompt", duration: Infinity })
    ProtectedAxios.get(`/users/getTemplatePrompt/${template_id}`)
      .then(res => {
        setLoadingSelectedTemplateStructure(false)
        setSelectedTemplateStructure(res.data.prompt)
        toast.dismiss("load-prompt")
        functionToExecute()
      })
      .catch(err => {
        console.log(err);
        setLoadingSelectedTemplateStructure(false)
        toast.dismiss("load-prompt")

        if (err.response.data.error) {
          return toast.error(err.response.data.error)
        }

        toast.error("Could not get your template prompt! Please try again later.")
      })
  }

  return (
    <div className='container py-5'>
      <Helmet>
        <title>Shared Templates - SageCollab</title>
      </Helmet>

      {user.role_id === 1
        &&
        <h2>Create Templates for Marketplace</h2>
      }
      {user.role_id === 1
        ?
        <p>Create prompt templates that users can install from template marketplace and use/edit them as their own.</p>

        :
        <>
        </>
      }
      <div className='template-header'>
        <div className='w-100 d-flex justify-content-end align-items-center my-4 gap-3'>
          <form className='input-with-icon w-100' onSubmit={fetchTemplates}>
            <input
              className='form-input'
              type="text"
              placeholder="search template"
              value={templateSearchText}
              onChange={e => setTemplateSearchText(e.target.value)}
            />
            {templateSearchText.length
              ?
              <button type="submit" className='button-icon edit-btn' title='search'><MdSearch className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
              :
              <></>
            }
          </form>

          <div className={`category-select-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''} position-relative`}>
            <button className='edit-btn' onClick={() => setCategoryDropdownVisible(prev => !prev)}>
              <BiFilter className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} />
            </button>
            <div ref={categoryFilterDropdownRef} className={`searchable-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''}`}>
              <div className="dropdown-content">
                <div className="dropdown-header">
                  <div className='dropdown-title'>
                    <p>Filter by cateogry</p>
                  </div>
                  <input type='text' placeholder='search category' value={searchTemplateCategoryText} onChange={e => setSearchTemplateCategoryText(e.target.value)} />
                </div>

                <div className="category-list">
                  {
                    fetchingTemplateCategories
                      ?
                      <div className="d-flex mt-4 w-100 h-100 gap-2 justify-content-center align-items-center flex-column">
                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <p className="font-xxs">
                          Loading categories
                        </p>
                      </div>
                      :
                      templateCategories.length === 0
                        ?
                        <div className="d-flex mt-4 w-100 h-100 justify-content-center align-items-center flex-column">
                          <BiCategoryAlt className="fs-3" />
                          <p className="font-xxs">
                            Nothing found
                          </p>
                        </div>
                        :
                        <div className="dropdown-categories">
                          {
                            templateCategories.map((category, i) => {
                              return (
                                <div key={i} onClick={() => { selectTemplateCategory(category); setCategoryDropdownVisible(false) }} className={`filter-categories-category tag tag-color-${i % 4 + 1}`}>
                                  {category.category}
                                </div>
                              )
                            })
                          }
                        </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="applied-filters">
        {searchingWithText && templateSearchText.length > 0
          &&
          <span className={`tag tag-search editing`}>search: {searchText} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { setTemplateSearchText(""); setSearchText(""); fetchTemplates() }}><GrFormClose className={`edit-icon`} /></button></span>
        }
        {selectedFilterCategories.length > 0
          &&
          selectedFilterCategories.map((category, i) => {
            return (
              <span className={`tag tag-color-${i % 4 + 1} editing`}>{category.category} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeTemplateCategory(category) }}><GrFormClose className={`edit-icon`} /></button></span>
            )
          })
        }
      </div>

      {loadingTemplates
        ?
        <div>
          <div className='pt-4'>
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
          </div>
        </div>

        :
        <div className='table-container'>
          <table className='templates-table w-100'>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
                {user.role_id === 1
                  &&
                  <th>Company</th>
                }
                <th>Categories</th>
                <th>Modified</th>
                <th>Action</th>
              </tr>
            </thead>

            {templates.length === 0
              ?
              <tbody>
                <tr>
                  <td colSpan={user.role_id === 1 ? 7 : 6}>No templates found</td>
                </tr>
              </tbody>

              :
              <tbody>
                {templates.map((template, i) => {
                  return (
                    <tr key={i} className={`${(selectedTemplate?.template_id === template.template_id && deletingTemplate) ? 'deleting-key' : ''}`}>
                      <td style={{ width: "3rem" }}>{i + 1}</td>
                      <td className='d-flex flex-column gap-2'>
                        {template.name}
                        {template.is_shared
                          ?
                          <span className='shared-tag'>shared with you</span>
                          : ''
                        }
                      </td>
                      <td style={{ minWidth: '8rem' }}>{template.description}</td>

                      {user.role_id === 1
                        &&
                        <td>
                          {template.selectedCompany !== null
                            ? template.selectedCompany.company_name
                            :
                            <span className='d-flex justify-content-center'>-</span>
                          }
                        </td>
                      }

                      <td>
                        {template.selectedCategories.length === 0
                          ? ' - '
                          :
                          template.selectedCategories.map(category => category.category.toLowerCase()).join(", ")
                        }
                      </td>

                      <td>{formatDate(new Date(template.updated_at))}</td>

                      <td className='py-md-1 d-flex align-items-center'>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Use template in chat
                            </Tooltip>
                          )}
                          placement="right"
                        >
                          <Link to={`/start-chat?templateId=${template.template_id}`} className='edit-btn dropdown-toggle'><RiChatUploadLine className='edit-icon copy-icon' /></Link>
                        </OverlayTrigger>

                        <MyDropdown position='right'>
                          <div
                            className='dropdown-item'
                            onClick={async (e) => {
                              setSelectedTemplate(template);
                              await setTemplateStructure(template.template_id, () => {
                                openViewTemplateModal()
                              })
                            }}
                          >
                            Preview
                          </div>

                          <div className='dropdown-item' onClick={(e) => { setSelectedTemplate(template); openConfirmDeleteSharedTemplateModal() }}>
                            Delete
                          </div>

                        </MyDropdown>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            }
          </table>
        </div>
      }

      {/* Confirm delete shared template Modal */}
      <Modal show={confirmDeleteSharedTemplateModal} onHide={closeConfirmDeleteSharedTemplateModal} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={removeTemplateFromSharedList}>
          <Modal.Body>
            <p className='my-3 px-3'>
              <span style={{ display: "block", fontWeight: "500" }}>{selectedTemplate?.name}</span>
              <span style={{ display: "block", marginBottom: "20px", color: "var(--color-light)" }}>{selectedTemplate?.description.substring(0, 60)}{selectedTemplate?.description.length > 60 && "..."}</span>
              Please confirm that you want to delete this shared template. You will no longer be able to access this template.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className='button button-danger' type='submit' disabled={deletingTemplate}>
              {deletingTemplate
                ?
                <>
                  Yes, delete
                  <div className="mx-2 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                  </div>
                </>
                : 'Yes, delete'
              }
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* View Template Modal */}
      < Modal show={viewTemplateModal} onHide={closeViewTemplateModal} centered size="md" >
        <Modal.Header closeButton>
          <Modal.Title>
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='h-100 overflow-scroll'>
            <h5>{selectedTemplate?.name}</h5>
            <p>{selectedTemplate?.description}</p>
            <div className=''>
              {renderSelectedTemplatePreview(selectedTemplateStructure, setSelectedTemplateStructure, true)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <button className='button' onClick={() => navigate(`/start-chat?templateId=${selectedTemplate?.template_id}`)}>Use in chat</button>
          </div>
        </Modal.Footer>
      </Modal >

    </div>
  )
}

export default SharedTemplates