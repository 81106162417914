import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IoSend } from 'react-icons/io5'
import { VscRefresh } from 'react-icons/vsc'
import { MdRefresh } from 'react-icons/md'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import Template from './Template'
import useSubscriptionDetails from '../hooks/useSubscriptionDetails'
import { toast } from 'react-hot-toast'
import CustomModal from './modals/CustomModal'
import { Link, NavLink } from 'react-router-dom'
import AdModal from './AdModal'
import logo from '../assets/logo.svg'
import MaxAiInteractionsReachedModal from './modals/MaxAiInteractionsReachedModal'


const NewMessage = ({ chat_id, tokens, updateTokens, chatDetails, chatDetailsSetter, addTempMessage, addMessage, removeLastTempMessage }) => {
    const [user] = useContext(UserContext)
    const [subscriptionDetail] = useSubscriptionDetails()

    const [addingMessage, setAddingMessage] = useState(false)

    const [promptText, setPromptText] = useState('')

    const [isUsingSelfAPI, setIsUsingSelfAPI] = useState(true)

    const [showMaxInteractionsReachedModel, setShowMaxInteractionsReachedModal] = useState(false)
    const [showAdModal, setShowAdModal] = useState(false)

    const textboxRef = useRef(null)

    const addNewMessage = (e, prompt) => {
        if (e) {
            e.preventDefault()
        }

        if (!chatDetails.is_new_chat && process.env.REACT_APP_SHOW_AD === "true" && subscriptionDetail.price_id === process.env.REACT_APP_PRICE_A_ID) {
            setShowAdModal(true)
        }

        //reset textarea rows
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        if (screenWidth <= 786) {
            textarea.rows = 2;
        }
        else {
            textarea.rows = 1;
        }

        setAddingMessage(true)
        toast.loading("Generating response...", { id: 'add-message', duration: Infinity })

        // add a temp message
        if (!chatDetails.is_new_chat) {
            let createdAt = new Date().toISOString()
            addTempMessage({ chat_id, created_by: user.user_id, profile_picture_src: user.profile_picture_src, message_content: promptText, username: user.username, name: user.name, created_at: createdAt })
            setTimeout(() => {
                document.getElementById(`msg-${createdAt}`)?.scrollIntoView({ behavior: "smooth" })
            }, 200)
        }

        const promptBackup = prompt

        // Clear the message textbox
        setPromptText("")

        const startTime = performance.now()

        ProtectedAxios.post("users/addMessage", { user_id: user.user_id, role_id: user.role_id, chat_id, prompt: prompt, customer_id: user.stripe_customer_id, is_using_self_api: isUsingSelfAPI, model_provider: chatDetails.model_provider, template_id: chatDetails?.template_ref?.template_id })
            .then(res => {
                const endTime = performance.now()
                let timeTaken = endTime - startTime
                let waitingTime = 15000 - timeTaken

                if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0
                }

                setTimeout(() => {
                    setShowAdModal(false)

                    //add new message in the messages state
                    if (res.data) {
                        chatDetailsSetter(prev => { return { ...prev, total_tokens: res.data.total_tokens } })
                        addMessage(res.data)
                    }

                    //update the tokens
                    try {
                        updateTokens()
                    }
                    catch (err) {
                        console.log(err);
                    }

                    //show success messages
                    toast.success('Response generated!', { id: 'add-message', duration: 3000 });
                    setAddingMessage(false)

                    //scroll to the new message
                    setTimeout(() => {
                        var element = document.getElementById(`message-${res.data.message_id}`);
                        var headerOffset = 250;
                        var elementPosition = element.getBoundingClientRect().top;
                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth"
                        });
                    }, 500)
                }, waitingTime)
            })
            .catch(err => {
                removeLastTempMessage()
                setPromptText(promptBackup)
                console.log(err);
                
                const endTime = performance.now()
                let timeTaken = endTime - startTime
                let waitingTime = process.env.REACT_APP_AD_DURATION - timeTaken

                if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0
                }

                setTimeout(() => {
                    setShowAdModal(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error, { id: 'add-message' })
                    }
                    else if (err.response.status === 401) {
                        toast.error("You dont have enough tokens to add this message, please topup to continue", { id: 'add-message' })
                    }
                    else if (err.response.status === 429) {     //max ai interactions reached
                        setShowMaxInteractionsReachedModal(true)
                        toast.dismiss("add-message")
                    }
                    setAddingMessage(false)
                }, waitingTime)
            })
    }

    useEffect(() => {
        if (chatDetails.is_new_chat) {
            addNewMessage(null, chatDetails.message_content)
        }
    }, [chatDetails])

    useEffect(() => {
        handleTextareaChange()
    }, [])
    const handleTextareaChange = (e, stateSetterFunction) => {
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        if (textarea) {

            const lineCount = textarea.value.split('\n').length;

            let size = parseInt(getComputedStyle(document.body).getPropertyValue('--size-xs').slice(0, -2))

            // Calculate the number of rows needed based on line count and content height
            const rowsNeeded = Math.max(lineCount, textarea.scrollHeight / size / 2);

            if (textarea.value.length === 0) {
                if (screenWidth <= 786) {
                    textarea.rows = 2;
                }
                else {
                    textarea.rows = 1;
                }
            } else {
                textarea.rows = rowsNeeded;
            }

            if (stateSetterFunction) {
                stateSetterFunction(textarea.value)
            }
        }
    }


    const footerRef = useRef(null);
    const footer = document.getElementById("footer");
    const [isFooterVisible, setIsFooterVisible] = useState(false)
    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.intersectionRatio >= 0.9) {
                    setIsFooterVisible(entry.isIntersecting);
                } else {
                    setIsFooterVisible(false);
                }
            }, {
                root: null,
                threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            }
            ),
        [],
    );

    useEffect(() => {
        observer.observe(footer);

        return () => {
            observer.disconnect();
        };

    }, [footer, observer]);



    return (
        <>
            <div className={`chat-sticky-foote r ${!isFooterVisible ? "is-sticky" : ""}`} ref={footerRef}>
                <div className='container py-5 mt-3 mb-5'>
                    {chatDetails.total_tokens < chatDetails.max_token_limit
                        ?
                        <>
                            <div className='d-flex mb-1'>
                                {chatDetails.model_provider === "OPENAI"
                                    ?
                                    <div className='chat-select-item' title={`Using OpenAI model ${chatDetails.model}`}>
                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                            <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} />
                                            <p className='m-0 font-xs'>OpenAI</p>
                                        </div>
                                    </div>

                                    : chatDetails.model_provider === "GEMINI"
                                        ?
                                        <div className='chat-select-item' title={`Using Gemini model ${chatDetails.model}`}>
                                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} />
                                                <p className='m-0 font-xs'>Gemini</p>
                                            </div>
                                        </div>

                                        : chatDetails.model_provider === "BEDROCK"
                                            ?
                                            <div className='chat-select-item' title={`Using Gemini model ${chatDetails.model}`}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} />
                                                    <p className='m-0 font-xs'>Bedrock</p>
                                                </div>
                                            </div>

                                            :
                                            <div className='chat-select-item'>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img src={logo} style={{ width: '20px' }} />
                                                    <p className='m-0 font-xs'>SageCollab</p>
                                                </div>
                                            </div>
                                }
                            </div>
                            <form ref={textboxRef} id='add-message-form' className='add-message-container' onSubmit={e => addNewMessage(e, promptText)}>
                                <div className='textarea-container template-grp'>
                                    <button id='copy-promptReply-response-button' disabled={promptText.length === 0 || addingMessage} className='send-message-button' title='send'>
                                        {addingMessage
                                            ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            : <IoSend className='edit-icon copy-icon' />
                                        }
                                    </button>
                                    <textarea
                                        placeholder="type here to continue or type '/' to select a template"
                                        rows={1}
                                        required
                                        id="prompt-input"
                                        value={promptText}
                                        disabled={addingMessage}
                                        onChange={e => handleTextareaChange(e, setPromptText)}
                                    >
                                    </textarea>
                                    <Template placement='top' textInput={promptText} handleTextareaChange={handleTextareaChange} setPrompt={setPromptText} showNormalOnly={true} />
                                </div>

                                {/* {((subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_B_ID || subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID) && tokens > 0 && chatDetails.model_provider === "OPENAI")
                                    &&
                                    <div className='d-flex justify-content-start gap-4 flex-wrap mt-2'>
                                        <div className="input-grp flex-row gap-2 align-items-end w-auto">
                                            <input disabled={addingMessage} className="form-check-input my-checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!isUsingSelfAPI} onChange={() => setIsUsingSelfAPI(false)} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                SageCollab Tokens
                                            </label>
                                        </div>
                                        <div className="input-grp flex-row gap-2 align-items-end w-auto">
                                            <input disabled={addingMessage} className="form-check-input my-checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={isUsingSelfAPI} onChange={() => setIsUsingSelfAPI(true)} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                My API key
                                            </label>
                                        </div>
                                    </div>
                                } */}
                            </form>
                        </>
                        :
                        <div className='d-flex mt-5 flex-column align-items-center'>
                            <div className='chat-info mb-5'>
                                <span>Max tokens limit reached</span>
                            </div>
                            <Link to="/start-chat" className='button'>Start New Chat</Link>
                        </div>
                    }
                </div>
            </div>

            <MaxAiInteractionsReachedModal
                show={showMaxInteractionsReachedModel}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
            />

            <AdModal
                show={showAdModal}
                onHide={() => setShowAdModal(false)}
            />
        </>
    )
}

export default NewMessage