import React, { useState, useRef, useCallback, useEffect } from 'react';

const NoteTextArea = ({ text, setText, userList, loadingUserList, disabled, mentionedUsers, setMentionedUsers }) => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [caretIndex, setCaretIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);

  const textareaRef = useRef(null);
  const formattedTextRef = useRef(null);

  const updateMentionedUsers = useCallback((newText) => {
    const mentionRegex = /@([\w.]+)/g;
    const newMentions = [...newText.matchAll(mentionRegex)].map(match => match[1]);
    const newMentionedUsers = userList.filter(user => newMentions.includes(user.username));
    setMentionedUsers(newMentionedUsers);
  }, [userList, setMentionedUsers]);

  const syncScroll = useCallback(() => {
    if (formattedTextRef.current && textareaRef.current) {
      formattedTextRef.current.scrollTop = textareaRef.current.scrollTop;
      formattedTextRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  }, []);

  const handleMentionSelect = useCallback((user) => {
    const beforeCaret = text.slice(0, caretIndex);
    const afterCaret = text.slice(caretIndex);
    const lastAtPos = beforeCaret.lastIndexOf('@');
    const newText = beforeCaret.slice(0, lastAtPos) + `@${user.username} ` + afterCaret;

    setText(newText);
    setShowDropdown(false);

    setMentionedUsers(prevUsers => {
      if (!prevUsers.some(u => u.username === user.username)) {
        return [...prevUsers, user];
      }
      return prevUsers;
    });

    if (textareaRef.current) {
      const newCaretPos = lastAtPos + user.username.length + 2;
      setTimeout(() => {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(newCaretPos, newCaretPos);
      }, 0);
    }
  }, [text, caretIndex, setText, setMentionedUsers]);

  const handleTextChange = useCallback((e) => {
    const value = e.target.value;
    const caretPos = e.target.selectionStart;
    setCaretIndex(caretPos);
    setText(value);

    const lastWord = value.slice(0, caretPos).split(/\s/).pop();

    if (lastWord.startsWith('@')) {
      const searchTerm = lastWord.slice(1).toLowerCase();
      const matchingUsers = userList.filter(user =>
        user.username.toLowerCase().includes(searchTerm)
      );
      setFilteredUsers(matchingUsers);
      setShowDropdown(matchingUsers.length > 0);
      setActiveIndex(-1);
    } else {
      setShowDropdown(false);
    }

    updateMentionedUsers(value);
  }, [userList, setText, updateMentionedUsers]);

  const handleKeyDown = useCallback((e) => {
    if (showDropdown) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex(prev => {
          const newIndex = e.key === 'ArrowDown' ? prev + 1 : prev - 1;
          return (newIndex + filteredUsers.length) % filteredUsers.length;
        });
      } else if (e.key === 'Enter' && activeIndex !== -1) {
        e.preventDefault();
        handleMentionSelect(filteredUsers[activeIndex]);
      } else if (e.key === 'Escape') {
        setShowDropdown(false);
      }
    }

    // Handle mention deletion when pressing backspace or delete
    if ((e.key === 'Backspace' || e.key === 'Delete') && textareaRef.current) {
      const cursorPos = textareaRef.current.selectionStart;

      // Check if a mention is being backspaced
      const mentionRegex = /@([a-zA-Z0-9_]+)/g;
      let lastMentionMatch;
      let match;
      while ((match = mentionRegex.exec(text)) !== null) {
        if (mentionRegex.lastIndex >= cursorPos) break;
        lastMentionMatch = match;
      }

      if (lastMentionMatch) {
        const mentionStart = lastMentionMatch.index;
        const mentionEnd = mentionStart + lastMentionMatch[0].length;
        if (cursorPos <= mentionEnd && cursorPos > mentionStart) {
          e.preventDefault(); // Prevent default backspace behavior
          const newText = text.slice(0, mentionStart) + text.slice(mentionEnd);
          setText(newText);

          // Remove mentioned user from the list
          const updatedMentionedUsers = mentionedUsers.filter(
            user => user.name !== lastMentionMatch[1]
          );
          setMentionedUsers(updatedMentionedUsers);

          // Move cursor to mention start position
          textareaRef.current.setSelectionRange(mentionStart, mentionStart);
          return;
        }
      }
    }
  }, [showDropdown, activeIndex, filteredUsers, handleMentionSelect, text, setText, mentionedUsers, setMentionedUsers]);

  const highlightMentions = (text) => {
    const parts = text.split(/(@[\w.]+)/g);
    const formatted = parts.map((part, index) => {
      if (part.startsWith('@') && mentionedUsers.some(user => `@${user.username}` === part)) {
        return <span key={index} className="mention">{part}</span>;
      }
      return part;
    });
    formatted.push(<br key={Date.now()} />)
    return formatted
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    const formattedText = formattedTextRef.current;

    const resizeObserver = new ResizeObserver(syncScroll);

    if (textarea && formattedText) {
      textarea.addEventListener('scroll', syncScroll);
      textarea.addEventListener('input', syncScroll);
      resizeObserver.observe(textarea);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('scroll', syncScroll);
        textarea.removeEventListener('input', syncScroll);
        resizeObserver.disconnect();
      }
    };
  }, [syncScroll]);

  return (
    <div className="w-100 position-relative">
      <div className="textarea-wrapper">
        <div
          className="textarea-formatted-text"
          ref={formattedTextRef}
        >
          {highlightMentions(text)}
        </div>
        <textarea
          ref={textareaRef}
          value={text}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          className="w-100 textarea-field"
          placeholder="Type something and mention users with @"
          autoFocus
          spellCheck={false}
        />
      </div>

      {showDropdown && (
        <div className="position-absolute" style={{
          bottom: "100%", right: 0, zIndex: 1000, maxHeight: '200px',
          overflowY: 'auto', background: 'white', border: '1px solid #ccc',
          borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          marginBottom: '4px'
        }}>
          {
            loadingUserList
              ? <div
                className={`p-2 ps-3 pe-4 mention-dropdown-list-item d-flex align-items-center gap-2`}
              >
                <span>
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                  </div>
                </span>
                <span>
                  Loading
                </span>
              </div>

              :
              filteredUsers.map((user, index) => (
                <div
                  key={user.user_id}
                  className={`p-2 ps-3 pe-4 mention-dropdown-list-item d-flex align-items-center gap-1 ${index === activeIndex ? 'background-lighter' : ''}`}
                  onClick={() => handleMentionSelect(user)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setActiveIndex(index)}
                >
                  <img src={user.profile_picture_src} className='profile-picture w-l h-l' alt={user.user_name} />
                  <span>
                    {user.username}
                  </span>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default NoteTextArea;
