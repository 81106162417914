import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { GrFormAdd, GrFormClose } from 'react-icons/gr'
import { MdEdit } from 'react-icons/md'
import { RiCheckLine } from 'react-icons/ri'
import { AiFillTag } from 'react-icons/ai'
import { toast } from 'react-hot-toast'
import ManageTags from './ManageTags'
import { UserContext } from '../context/UserProvider'

const Tags = ({ downloadingPdf, chat_id, isViewingWithSharedAccess, isEnterpriseAdmin, refreshTags, setRefreshTags }) => {
    const [user] = useContext(UserContext)

    const [selectedTags, setSelectedTags] = useState([])
    const [tagText, setTagText] = useState('')
    const [editingTags, setEditingTags] = useState(false)

    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        fetchPromptTags()
    }, [chat_id, refresh, refreshTags])

    const fetchPromptTags = () => {
        ProtectedAxios.post('/users/chatTags', { chat_id })
            .then(res => {
                setSelectedTags(res.data)
            })
            .catch(err => {
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const addTag = () => {
        if (tagText && !isViewingWithSharedAccess) {
            ProtectedAxios.post('/users/addTag', { chat_id, tagName: tagText })
                .then(res => {
                    if (res.data) {
                        setRefresh(!refresh)
                        setTagText('')
                    }
                })
                .catch(err => {
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })
        }
    }

    const removeTag = tag_id => {
        ProtectedAxios.post('/users/removeTag', { chat_id, tag_id: tag_id })
            .then(res => {
                if (res.data) {
                    setRefresh(!refresh)
                }
            })
            .catch(err => {
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    return (
        <div className='tags-container'>
            <div className='selected-tag-container' style={{ display: 'flex', gap: '1rem', padding: '1rem 0.5rem', flexWrap: 'wrap' }}>
                {selectedTags.length > 0
                    ?
                    <>
                        {selectedTags.map((tag, i) => {
                            return (
                                <span key={i} style={{ borderRadius: '30px', fontSize: '0.9rem', }} className={`tag tag-color-${i % 4 + 1} ${editingTags && 'editing'}`}>{tag.tag} {editingTags && <button type='button' title='remove tag' id='remove-tag-button' className={`edit-btn remove-tag-button`} value={tag.tag_id} onClick={e => { removeTag(e.target.value) }}><GrFormClose className={`edit-icon`} /></button>}</span>
                            )
                        })}
                    </>

                    :
                    <>
                        {(!isViewingWithSharedAccess && !isEnterpriseAdmin)
                            &&
                            <ManageTags setRefreshTags={setRefreshTags} noTags={true} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                        }
                    </>
                }
            </div>

            <div className='tag-form-container' id='tag-form'>
                <div className='tag-input-container' style={{ display: `${editingTags ? 'flex' : 'none'}` }}>
                    <input type='text' placeholder='&#9751; add tags' id='tag-input' value={tagText.toLowerCase()} onChange={e => { setTagText(e.target.value.toLowerCase()) }} />
                    <button type='button' className='edit-btn' title='add tag' onClick={() => addTag()}><GrFormAdd className='edit-icon accept-icon ' /></button>
                </div>

                <>
                    {(selectedTags.length > 0 && !isViewingWithSharedAccess && !isEnterpriseAdmin)
                        &&
                        <ManageTags setRefreshTags={setRefreshTags} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                    }
                </>
            </div>

        </div>
    )
}

export default Tags