import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../context/UserProvider';
import ProtectedAxios from '../api/protectedAxios'
import { NavLink, useNavigate } from 'react-router-dom';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import AddOpenaiAPIkeyModal from './AddOpenaiAPIkeyModal';
import { toast } from 'react-hot-toast';
import Template from './Template';
import { IoSend } from 'react-icons/io5';
import Settings from './settings/Settings';
import MyDropdown from './MyDropdown';
import { BiBookmark, BiSad } from 'react-icons/bi';
import { EventContext } from '../context/EventProvider';
import { MdArrowBack, MdOutlineRefresh } from 'react-icons/md';
import LoadingSkeleton from './LoadingSkeleton';
import GoogleAd from './GoogleAd';
import ScriptRunner from './ScriptRunner';
import CustomModal from './modals/CustomModal';
import AdModal from './AdModal';
import AddGeminiAPIkeyModal from './AddGeminiAPIkeyModal';
import { GrClose } from 'react-icons/gr';
import logo from '../assets/logo.svg'
import MaxAiInteractionsReachedModal from './modals/MaxAiInteractionsReachedModal';
import AddBedrockAPIkeyModal from './AddBedrockAPIkeyModal';



const NewChat = ({ updateTokens, tokens }) => {
    const { eventData, emitEvent } = useContext(EventContext)
    const [user, setUser] = useContext(UserContext)
    const [subscriptionDetail] = useSubscriptionDetails(user.stripe_customer_id)

    const [selectedModelProvider, setSelectedModelProvider] = useState("DEFAULT")

    const [prompt, setPrompt] = useState('')
    const [isUsingSelfAPI, setIsUsingSelfAPI] = useState(true)

    const templateRef = useRef(null)
    const [savingPrompt, setSavingPrompt] = useState(false)
    const [loading, setLoading] = useState(false)

    const [templateId, setTemplateId] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const navigate = useNavigate()

    const [showOpenaiApiKeyModal, setShowOpenaiApiKeyModal] = useState(false)
    const [showGeminiApiKeyModal, setShowGeminiApiKeyModal] = useState(false)
    const [showBedrockApiKeyModal, setShowBedrockApiKeyModal] = useState(false)
    const [showMaxInteractionsReachedModal, setShowMaxInteractionsReachedModal] = useState(false)
    const [showAdModal, setShowAdModal] = useState(false)

    useEffect(() => {
        if (selectedModelProvider === "GEMINI") {
            setIsUsingSelfAPI(true)
        }
    }, [selectedModelProvider])

    const handleSubmit = async (e, _key, _prompt, _templateId) => {


        let apiKey =
            _key
                ? _key
                : selectedModelProvider === "OPENAI" ? user.openai_api_key
                    : selectedModelProvider === "GEMINI" ? user.gemini_api_key
                    : selectedModelProvider === "BEDROCK" && user.bedrock_access_key


        if (e) {
            e.preventDefault()
        }

        if (apiKey || user.role_id === 5 || !isUsingSelfAPI || selectedModelProvider === "DEFAULT") {
            if (process.env.REACT_APP_SHOW_AD === "true" && subscriptionDetail.price_id === process.env.REACT_APP_PRICE_A_ID) {
                setShowAdModal(true)
            }

            setLoading(true)
            toast.loading('Generating response...', { id: 'new-chat', duration: Infinity });

            const startTime = performance.now()

            let updatedPrompt = prompt
            if (_prompt) {
                updatedPrompt = _prompt
            }

            let updated_template_id = selectedTemplate?.template_id
            if (_templateId) {
                updated_template_id = _templateId
            }

            ProtectedAxios.post('/users/newChat', { prompt: updatedPrompt, user_id: user.user_id, customer_id: user.stripe_customer_id, role_id: user.role_id, is_using_self_api: isUsingSelfAPI, model_provider: selectedModelProvider, template_id: updated_template_id })
                .then(res => {
                    if (res.data) {
                        const endTime = performance.now()
                        let timeTaken = endTime - startTime
                        let waitingTime = 15000 - timeTaken

                        if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                            waitingTime = 0
                        }

                        setTimeout(() => {
                            setShowAdModal(false)
                            setLoading(false)
                            try {
                                updateTokens()
                            }
                            catch (err) { console.log(err) }

                            setPrompt("")
                            navigate(`/chat/${res.data.chat_id}`)
                            toast.success('Response generated!', { id: 'new-chat', duration: 3000 });
                        }, waitingTime)

                    }
                })
                .catch(err => {
                    console.log(err);

                    const endTime = performance.now()
                    let timeTaken = endTime - startTime
                    let waitingTime = process.env.REACT_APP_AD_DURATION - timeTaken
                    if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                        waitingTime = 0
                    }

                    setTimeout(() => {
                        setShowAdModal(false)
                        setTemplateId(null)
                        setSelectedTemplate(null)
                        if (err.response.status === 500) {
                            toast.error(err.response.data.error, { duration: Infinity, id: 'new-chat' })
                            setLoading(false)
                        }
                        else if (err.response.status === 429) {     //max ai interactions reached
                            setShowMaxInteractionsReachedModal(true)
                            toast.dismiss("new-chat")
                            setLoading(false)
                        }
                        else if (err.response.status === 403) {     //plan limits reached
                            toast.error(err.response.data.error, { id: 'new-chat' })
                            navigate('/upgrade')
                            setLoading(false)
                        }
                        else if (err.response.status === 401) {     //not enough tokens
                            toast.error(err.response.data.error, { id: 'new-chat' })
                            setLoading(false)
                        }
                        else {
                            console.log(err);
                            setLoading(false)
                            toast.dismiss("new-chat")
                        }
                    }, waitingTime)
                })
        }
        else {
            if (selectedModelProvider === "OPENAI") {
                setShowOpenaiApiKeyModal(true)
            }
            else if (selectedModelProvider === "GEMINI") {
                setShowGeminiApiKeyModal(true)
            }
            else if (selectedModelProvider === "BEDROCK") {
                setShowBedrockApiKeyModal(true)
            }
        }


    }

    const savePrompt = () => {
        setSavingPrompt(true)
        ProtectedAxios.post('/users/addSavedPrompt', { prompt, user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    emitEvent({ eventName: "refreshSavedPrompts", timestamp: new Date() });
                    setSavingPrompt(false)
                    toast.success("Prompt saved")
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 500) {
                    setSavingPrompt(false)
                    toast.error(err.response.data.error)
                }
            })
    }

    const [fetchingTemplates, setFetchingTemplates] = useState(true)
    const [fetchingMoreTemplates, setFetchingMoreTemplates] = useState(false)
    const [templates, setTemplates] = useState([])
    const [templatePage, setTemplatePage] = useState(1)
    const refreshTemplateIconRef = useRef(null)

    const loadInitialTemplates = () => {
        setFetchingTemplates(true)
        ProtectedAxios.post("/users/getMarketplaceTemplatesForJumpstartSection", { page: 1 })
            .then(res => {
                if (res.data.length > 0) {
                    setTemplates(res.data)
                }
                setFetchingTemplates(false)

            })
            .catch(err => {
                console.log(err)
                setFetchingTemplates(false)
            })
    }

    const loadMoreTemplates = () => {
        setFetchingMoreTemplates(true)
        let updatedPage = templatePage + 1
        setTemplatePage(templatePage => templatePage + 1)
        ProtectedAxios.post("/users/getMarketplaceTemplatesForJumpstartSection", { page: updatedPage })
            .then(res => {
                if (res.data.length < 3 || res.data.length === 0) {
                    setTemplatePage(0)
                }
                if (res.data.length > 0) {
                    setTemplates(res.data)
                }
                setFetchingMoreTemplates(false)
            })
            .catch(err => {
                console.log(err)
                setFetchingMoreTemplates(false)
            })
    }

    useEffect(() => {
        loadInitialTemplates();
    }, []);



    const [fetchingPrompts, setFetchingPrompts] = useState(true);
    const [fetchingMorePrompts, setFetchingMorePrompts] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptPage, setPromptPage] = useState(1)

    const loadInitialPrompts = () => {
        setFetchingPrompts(true)
        ProtectedAxios.post("/users/getExplorerPrompts", { page: 1 })
            .then(res => {
                if (res.data.length > 0) {
                    setPrompts(res.data)
                }
                setFetchingPrompts(false)

            })
            .catch(err => {
                console.log(err)
                setFetchingPrompts(false)
            })
    }

    const loadMorePrompts = () => {
        setFetchingMorePrompts(true)
        let updatedPage = promptPage + 1
        setPromptPage(page => page + 1)
        ProtectedAxios.post("/users/getExplorerPrompts", { page: updatedPage })
            .then(res => {
                if (res.data.length < 3 || res.data.length === 0) {
                    setPromptPage(0)
                }
                if (res.data.length > 0) {
                    setPrompts(res.data)
                }
                setFetchingMorePrompts(false)
            })
            .catch(err => {
                console.log(err)
                setFetchingMorePrompts(false)
            })
    }

    useEffect(() => {
        loadInitialPrompts();
    }, []);


    const handleTextareaChange = (e, stateSetterFunction) => {
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        const lineCount = textarea.value.split('\n').length;

        let size = parseInt(getComputedStyle(document.body).getPropertyValue('--size-xs').slice(0, -2))

        // Calculate the number of rows needed based on line count and content height
        const rowsNeeded = Math.max(lineCount, textarea.scrollHeight / size / 2);

        if (textarea.value.length === 0) {
            if (screenWidth <= 786) {
                textarea.rows = 2;
            }
            else {
                textarea.rows = 1;
            }
        } else {
            textarea.rows = rowsNeeded;
        }

        if (stateSetterFunction) {
            stateSetterFunction(textarea.value)
        }
    }

    useEffect(() => {
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        if (screenWidth <= 786) {
            textarea.rows = 2;
        }

    }, [])

    return (
        <div className='new-chat-container'>
            {/* <GoogleAd dataAdSlot={2886700133} /> */}
            <div className='flexed-between'>
                <div className="d-flex align-items-center gap-2">
                    <button type="submit" className='button-icon edit-btn' title='back' onClick={() => navigate(-1)}><MdArrowBack className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                    <h2 className="text-left w-100 title m-0">Start A.I. Chat</h2>
                </div>
                {/* <h2 className='text-left w-100 title'>Start A.I. Chat</h2> */}
                <Settings text="Settings" title="chat settings" viewAsLisk={false} />
                {/* <MyDropdown>
                    <div className='dropdown-item'>
                        <Settings text="Settings" viewAsLisk={true} />
                    </div>
                    <div className='dropdown-item'>
                        <Settings text="Model" viewAsLisk={true} />
                    </div>
                    <div className='dropdown-item'>
                        <Settings text="API Key" viewAsLisk={true} />
                    </div>
                </MyDropdown> */}
            </div>

            <div className='chat-assistance-main'>
                <div className='chat-assistance-container'>
                    <div className='chat-assistance'>
                        <button className='edit-btn' disabled={fetchingMoreTemplates} onClick={() => { loadMoreTemplates(); document.getElementById("refresh-templates-icon").style.transform = `rotate(${360 * templatePage}deg)` }}>
                            <MdOutlineRefresh
                                className='w-m h-m'
                                id="refresh-templates-icon"
                            />
                        </button>
                        <h4>Jumpstart with templates</h4>
                        <div className='assistant-items'>
                            {fetchingTemplates || fetchingMoreTemplates
                                ?
                                <>
                                    <LoadingSkeleton type='tags assistant-item' />
                                    <LoadingSkeleton type='tags assistant-item' />
                                    <LoadingSkeleton type='tags assistant-item' />
                                </>
                                :
                                <>
                                    {templates.length > 0
                                        ?
                                        templates.map((template, i) => {
                                            return (
                                                <div key={i} className='assistant-item' title={template.name} onClick={() => { setTemplateId(template.template_id) }}><span className='font-xxs text-truncate'>{template.name}</span></div>
                                            )
                                        })

                                        :
                                        <>
                                            <BiSad className='w-xl h-xl mb-2 mt-3' style={{ color: 'var()' }} />
                                            <p className='subtitle'>Nothing to show here at the moment</p>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className='chat-assistance'>
                        <button className='edit-btn' disabled={fetchingMorePrompts} onClick={() => { loadMorePrompts(); document.getElementById("refresh-prompts-icon").style.transform = `rotate(${360 * promptPage}deg)` }}>
                            <MdOutlineRefresh
                                className='w-m h-m'
                                id="refresh-prompts-icon"
                            />
                        </button>
                        <h4>Explore</h4>
                        <div className='assistant-items'>
                            {fetchingPrompts || fetchingMorePrompts
                                ?
                                <>
                                    <LoadingSkeleton type='tags assistant-item' />
                                    <LoadingSkeleton type='tags assistant-item' />
                                    <LoadingSkeleton type='tags assistant-item' />
                                </>
                                :
                                <>
                                    {prompts.length > 0
                                        ?
                                        prompts.map((prompt, i) => {
                                            return (
                                                <div key={i} className='assistant-item' title={prompt.prompt_title} onClick={() => setPrompt(prompt.prompt_text)}><span className='font-xxs text-truncate'>{prompt.prompt_title}</span></div>
                                            )
                                        })

                                        :
                                        <>
                                            <BiSad className='w-xl h-xl mb-2 mt-3' style={{ color: 'var()' }} />
                                            <p className='subtitle'>Nothing to show here at the moment</p>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-100'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex chat-select'>
                        <div
                            onClick={() => setSelectedModelProvider("DEFAULT")}
                            className={`chat-select-item ${selectedModelProvider === "DEFAULT" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src={logo} style={{ width: '20px' }} />
                                <p className='m-0 font-xs'>SageCollab</p>
                            </div>
                        </div>

                        <div
                            onClick={() => setSelectedModelProvider("OPENAI")}
                            className={`chat-select-item ${selectedModelProvider === "OPENAI" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} />
                                <p className='m-0 font-xs'>OpenAI</p>
                            </div>
                        </div>

                        <div
                            onClick={() => setSelectedModelProvider("GEMINI")}
                            className={`chat-select-item ${selectedModelProvider === "GEMINI" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} />
                                <p className='m-0 font-xs'>Gemini</p>
                            </div>
                        </div>

                        <div
                            onClick={() => setSelectedModelProvider("BEDROCK")}
                            className={`chat-select-item ${selectedModelProvider === "BEDROCK" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} />
                                <p className='m-0 font-xs'>Bedrock</p>
                            </div>
                        </div>
                    </div>

                    {/* {prompt.length > 0
                        &&
                        <button type="button" className='edit-btn noTag-addBtn' onClick={() => savePrompt()}>
                            {savingPrompt
                                ?
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                :
                                <BiBookmark />
                            }
                            Save Prompt
                        </button>
                    } */}
                </div>

                <form id='add-message-form' className='add-message-container w-100' onSubmit={handleSubmit}>
                    <div className='textarea-container template-grp'>
                        <button type='submit' id='copy-promptReply-response-button' disabled={prompt.length === 0 || loading} className='send-message-button' title='start chat'>
                            {loading
                                ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                : <IoSend className='edit-icon copy-icon' />
                            }
                        </button>
                        <textarea
                            placeholder="start typing to chat or type '/' to select a template"
                            rows={1}
                            required
                            id="prompt-input"
                            value={selectedTemplate?.type === "CHAT_STARTER_PRIVATE" || selectedTemplate?.type === "CHAT_STARTER_OPEN" ? '' : prompt}
                            disabled={loading}
                            onChange={e => handleTextareaChange(e, setPrompt)}
                        />
                        <Template placement='top' textInput={prompt} setPrompt={setPrompt} template_id={templateId} setTemplateId={setTemplateId} setTemplate={setSelectedTemplate} handleTextareaChange={handleTextareaChange} submit={handleSubmit} />
                    </div>
                </form>

                <div className='d-flex justify-content-between align-items-center w-100' style={{ minHeight: "3rem" }}>
                    <div />
                    {/* {((subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_B_ID || subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID) && tokens > 0 && selectedModelProvider === "OPENAI")
                        ?
                        <div className='d-flex flex-column flex-md-row justify-content-start gap-1 gap-md-4 flex-wrap'>
                            <div className="input-grp flex-row gap-2 align-items-end w-auto">
                                <input disabled={loading} className="form-check-input my-checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!isUsingSelfAPI} onChange={() => setIsUsingSelfAPI(false)} />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    SageCollab Tokens
                                </label>
                            </div>
                            <div className="input-grp flex-row gap-2 align-items-end w-auto">
                                <input disabled={loading} className="form-check-input my-checkbox" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={isUsingSelfAPI} onChange={() => setIsUsingSelfAPI(true)} />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    My API key
                                </label>
                            </div>
                        </div>
                        :
                        <div />
                    } */}

                    {selectedTemplate
                        &&
                        <div className='selected-template' title={selectedTemplate?.description}>
                            <div className='d-flex align-items-center justify-content-between gap-3'>
                                <span className='font-xxs'>Selected Template</span>
                                <button onClick={() => { setSelectedTemplate(null); setPrompt("") }} className='bg-transparent border-0' disabled={loading}>
                                    <GrClose className='remove-icon' />
                                </button>
                            </div>
                            <div>
                                <p className='m-0 text-secondary font-xxs'>
                                    {selectedTemplate.name.substring(0, 20)}{selectedTemplate.name.length > 20 && "..."}
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>


            <AddOpenaiAPIkeyModal
                show={showOpenaiApiKeyModal}
                onHide={() => setShowOpenaiApiKeyModal(false)}
                title='Add your OpenAI API key to continue chat or use SageCollab tokens'
                handleSubmit={handleSubmit}
            />

            <AddGeminiAPIkeyModal
                show={showGeminiApiKeyModal}
                onHide={() => setShowGeminiApiKeyModal(false)}
                title='Add your Gemini API key to continue chat'
                handleSubmit={handleSubmit}
            />

            <AddBedrockAPIkeyModal
                show={showBedrockApiKeyModal}
                onHide={() => setShowBedrockApiKeyModal(false)}
                title='Add your Bedrock API key to continue chat'
                handleSubmit={handleSubmit}
            />

            {/* <CustomModal
                show={showMaxInteractionsReachedModal}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
                title={<h3 className='font-m m-0'>Max Limit Reached</h3>}
                content={
                    <div className='my-4'>
                        <h5>Upgrade Your Experience</h5>
                        <p>
                            You have reached maximum number of AI interactions in 24 hours. Please try again later.
                            <br />
                            Upgrade your account to access more AI interactions and premium features.
                        </p>
                    </div>
                }
                buttons={<>
                    <NavLink to="/upgrade" className='button px-4'>Check upgrade options</NavLink>
                </>}
            /> */}

            <MaxAiInteractionsReachedModal
                show={showMaxInteractionsReachedModal}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
            />

            <AdModal
                show={showAdModal}
                onHide={() => setShowAdModal(false)}
            />
        </div>
    )
}

export default NewChat