import React, { useContext, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { UserContext } from '../context/UserProvider';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const CreateUsername = () => {
    const [user, setUser] = useContext(UserContext)

    const [username, setUsername] = useState("")
    const [updatingUsername, setUpdatingUsername] = useState(false)
    const [usernameError, setUsernameError] = useState("")

    const urlParams = new URLSearchParams(window.location.search);
    const redirectTo = urlParams.get('redirectTo');

    const navigate = useNavigate();

    const updateUsername = (e) => {
        e.preventDefault()
        if (!username) {
            return setUsernameError("Provide a username to continue")
        }
        setUpdatingUsername(true)
        ProtectedAxios.post('/users/updateProfile', { user_id: user.user_id, editingField: "username", newValue: username, oldValue: null })
            .then(res => {
                if (res.data) {
                    setUser({ ...user, username: username })
                    toast.success("Username successfully created")
                    setUpdatingUsername(false)

                    if(redirectTo){
                        navigate(redirectTo);
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setUsernameError(err.response.data.error)
                setUpdatingUsername(false)
            })

    }
    return (
        <section className='container box align-items-star'>
            <Helmet>
                <title>Create Username - SageCollab</title>
            </Helmet>
            <div className='form md align-items-start'>
                <img style={{ width: "calc(var(--size-xxl) * 1.5)" }} src="./favicon.png" alt="logo" />
                <h2 className='text-center font-m'>Set Username</h2>
                <p className='text-secondary'>Create a unique username for your SageCollab account to continue</p>
                <form className='form-container' onSubmit={updateUsername}>
                    <div className='input-grp'>
                        <label htmlFor="username-email">Username <span className='text-danger'>*</span></label>
                        <input autoFocus type="text" id="username-email" value={username} onChange={e => { setUsernameError(""); setUsername(e.target.value) }} />
                    </div>

                    <div className='btn-container' style={{ marginTop: "-1rem" }}>
                        <p className='subtitle text-danger'>{usernameError}</p>
                        <button type="submit" className="button button-main w-100" disabled={updatingUsername}>
                            {updatingUsername
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Continue"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default CreateUsername