import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const GoogleAd = ({ dataAdSlot }) => {
    const location = useLocation()

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {
            console.log("ad error: ", e);
        }
    }, [location]);

    return (
        <>
            <ins
                className="adsbygoogle border"
                style={{ display: "block", width: "95%", height: "30dvh", borderRadius: "10px" }}
                data-ad-client="ca-pub-6268083714546656"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </>
    );
}

export default GoogleAd

{/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6268083714546656"
     crossorigin="anonymous"></script>
<!-- sgbt - header wide -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-6268083714546656"
     data-ad-slot="2886700133"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script> */}