import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserProvider";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectUsersWithNoUsername = () => {
    const [user] = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Only redirect if the user has an email but no username and the current path is not the root "/"
        if (user?.email && !user?.username && location.pathname !== "/") {
            navigate(`/?redirectTo=${location.pathname}${location.search}`);
        }
    }, [user, location, navigate]);

    return null;
};

export default RedirectUsersWithNoUsername;
