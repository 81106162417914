import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserProvider'
import ProtectedAxios from '../api/protectedAxios'
import toast from 'react-hot-toast'
import Modal from 'react-bootstrap/Modal';
import { HiClipboard, HiClipboardCheck } from 'react-icons/hi'
import { RiDeleteBin5Line } from 'react-icons/ri';
import { formatDate, formatLocalDate } from '../utils/helper';
import { BiEdit } from 'react-icons/bi';
import LoadingSkeleton from '../components/LoadingSkeleton';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';


const AccessKeys = () => {
    const [user] = useContext(UserContext)
    const [keys, setKeys] = useState([])
    const [loadingKeys, setLoadingKeys] = useState(true)

    const [newKey, setNewKey] = useState({
        access_key_name: "",
        expires_at: ""
    })
    const [selectedKey, setSelectedKey] = useState(null)

    const [addingKey, setAddingKey] = useState(false)
    const [keyAdded, setKeyAdded] = useState(false)
    const [deletingKey, setDeletingKey] = useState(false)
    const [updatingKey, setUpdatingKey] = useState(false)

    const [addKeyModal, setAddKeyModal] = useState(false)
    const closeAddKeyModal = () => {
        setAddKeyModal(false)
        setKeyAdded(false)
    }
    const [updateKeyModal, setUpdateKeyModal] = useState(false)
    const [deleteKeyModal, setDeleteKeyModal] = useState(false)

    const [error, setError] = useState("")
    const [keyCopied, setKeyCopied] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setKeyCopied(false)
        }, 2000)
    }, [keyCopied])

    useEffect(() => {
        getKeys()
    }, [])

    const getKeys = async () => {
        setLoadingKeys(true)
        ProtectedAxios.get('/users/getAccessKeys')
            .then(res => {
                if (res.data) {
                    setKeys(res.data)
                    setLoadingKeys(false)
                }
            })
            .catch(err => {
                setLoadingKeys(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const addKey = async () => {
        if (!newKey.access_key_name) {
            return setError("Please provide a name for this access key")
        }
        if (!newKey.expires_at) {
            return setError("Please provide an expiry time for this access key")
        }

        setAddingKey(true)
        ProtectedAxios.post('/users/createAccessKey', { access_key_name: newKey.access_key_name, expires_at: new Date(newKey.expires_at).toISOString(), user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setKeys(prev => {
                        return [res.data, ...prev]
                    })
                    setSelectedKey(res.data)
                    setKeyAdded(true)

                    setNewKey({
                        access_key_name: "",
                        expires_at: ""
                    })
                }
                setAddingKey(false)
            })
            .catch(err => {
                setAddingKey(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const updateKey = async () => {
        if (!selectedKey?.access_key_name) {
            return setError("Please provide a name for this access key")
        }
        if (!selectedKey?.expires_at) {
            return setError("Please provide an expiry time for this access key")
        }

        setUpdatingKey(true)
        ProtectedAxios.post(`/users/updateAccessKey`, { ...selectedKey, expires_at: new Date(selectedKey?.expires_at).toISOString() })
            .then(res => {
                if (res.data) {

                    const selectedKeyIndex = keys.findIndex(key => key.access_key_id === selectedKey?.access_key_id)
                    const updatedKeys = [...keys]
                    updatedKeys[selectedKeyIndex] = res.data
                    setKeys(updatedKeys)

                    setUpdateKeyModal(false)
                    setUpdatingKey(false)
                    toast.success(`Access key updated`)
                }
            })
            .catch(err => {
                setUpdatingKey(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const deleteKey = async (access_key_id, access_key_name) => {
        setDeletingKey(true)
        ProtectedAxios.delete(`/users/deleteAccessKey/${access_key_id}`)
            .then(res => {
                if (res.data) {
                    const filteredKeys = keys.filter(key => key.access_key_id !== access_key_id)
                    setKeys(filteredKeys)
                    setDeletingKey(false)
                    setDeleteKeyModal(false)
                    toast.success(`Access key '${access_key_name}' deleted`)
                }
            })
            .catch(err => {
                setDeletingKey(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    return (
        <div className='container py-5'>
            <Helmet>
                <title>Access Keys - SageCollab</title>
            </Helmet>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '2vh' }}>
                <h2 className='text-left title w-100'>Access Keys</h2>
                <p>
                    Access keys help you authenticate on other platforms.
                </p>
                <p className='text-secondary font-xxs mt-1'>
                    Please do not share your Access key with others, or expose it in the browser or other client-side code.
                    If you suspect that your Access key has been compromised, please generate a new one immediately.
                </p>

                <div className='w-100 d-flex justify-content-end my-4'>
                    <button className='button button-ghost' onClick={() => setAddKeyModal(true)}>+ Add Access Key</button>
                </div>
                <div className='table-container'>
                    <table className='api-keys-table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Access Key</th>
                                <th>Expires at</th>
                                <th>Created at</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loadingKeys
                                    ?
                                    [1, 2].map(i => (
                                        <tr key={i}>
                                            {
                                                [1, 2, 3, 4, 5, 6].map(j => (
                                                    <td key={j} className='py-3' style={{ paddingRight: "10px" }}><LoadingSkeleton type="td" /></td>
                                                ))
                                            }
                                        </tr>
                                    ))

                                    :
                                    keys.length === 0
                                        ?
                                        <tr>
                                            <td className='pt-3' colSpan={6}>
                                                <p className='font-weight-semibold m-0'>No keys found</p>
                                                <p className='text-secondary m-0'>Add keys to see them here</p>
                                            </td>
                                        </tr>

                                        :
                                        keys.map((key, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td style={{ width: "3rem" }}>{i + 1}</td>
                                                    <td>{key.access_key_name}</td>
                                                    <td className='flexed-cell'>
                                                        {key.access_key.substring(0, 7)}......{key.access_key.substring(key.access_key.length - 7, key.access_key.length)}
                                                        <div className='position-relative d-inline'>
                                                            <button disabled={deletingKey} type='button' className='edit-btn d-flex flex-column justify-content-center align-items-center' title='copy' value={key.access_key} onClick={e => { navigator.clipboard.writeText(e.target.value).then(() => { setSelectedKey(key); setKeyCopied(true) }) }}>
                                                                {!keyCopied
                                                                    ?
                                                                    <HiClipboard className='edit-icon copy-icon' />
                                                                    :
                                                                    <>
                                                                        {selectedKey?.access_key_id === key.access_key_id
                                                                            ?
                                                                            <>
                                                                                <HiClipboardCheck className='edit-icon copy-icon' />
                                                                                <span className='font-us'>
                                                                                    copied
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <HiClipboard className='edit-icon copy-icon' />
                                                                        }
                                                                    </>
                                                                }
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>{formatDate(new Date(key.expires_at))}</td>
                                                    <td>{formatDate(new Date(key.created_at))}</td>
                                                    <td>
                                                        <div className='edit-btn-container justify-content-start align-start px-2'>
                                                            <button className='edit-btn' onClick={(e) => { setSelectedKey(key); setDeleteKeyModal(true) }}><RiDeleteBin5Line className='edit-icon' /></button>
                                                            <button className='edit-btn' onClick={(e) => { setSelectedKey(key); setUpdateKeyModal(true) }}><BiEdit className='edit-icon' /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                        </tbody>
                    </table>
                </div>

            </div>


            <Modal show={addKeyModal} onHide={closeAddKeyModal} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Add Access Key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!keyAdded
                        ?
                        <div>
                            <div className='container'>
                                <div className='input-grp my-3'>
                                    <label htmlFor="key-name">Key Name <span className='text-danger'>*</span></label>
                                    <input type="text" id="key-name" value={newKey.access_key_name} onChange={e => { setError(""); setNewKey(prev => { return { ...prev, access_key_name: e.target.value } }) }} />
                                </div>
                                <div className='input-grp my-3'>
                                    <label htmlFor="key-expiry">Expires at <span className='text-danger'>*</span></label>
                                    <input type="datetime-local" id="key-expiry" value={newKey.expires_at} onChange={e => { setError(""); setNewKey(prev => { return { ...prev, expires_at: e.target.value } }) }} />
                                </div>
                                <p className='text-danger fs-7 my-2 mx-2' style={{ fontSize: '15px' }}>{error}</p>
                            </div>
                        </div>

                        :
                        <div>
                            <p className='key-added-success'>New access key successfully created</p>
                            <div className='added-key-copy-container'>
                                <div className='added-key'>{selectedKey?.access_key.substring(0, 7)}......{selectedKey?.access_key.substring(selectedKey?.access_key.length - 7, selectedKey?.access_key.length)}</div>
                                <div className='added-key-copy'>
                                    <button type='button' className='edit-btn d-flex justify-content-center align-items-center' title='copy' value={selectedKey?.access_key} onClick={e => navigator.clipboard.writeText(e.target.value).then(() => { setKeyCopied(true) })}>
                                        {!keyCopied
                                            ?
                                            <HiClipboard className='edit-icon copy-icon' />
                                            :
                                            <>
                                                <HiClipboardCheck className='edit-icon copy-icon' />
                                                <span className='font-us'>
                                                    copied
                                                </span>
                                            </>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!keyAdded
                        ?
                        <>
                            <button disabled={addingKey} className="button" onClick={() => addKey()}>
                                {addingKey
                                    ?
                                    <>
                                        Create
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </>

                                    : "Create"
                                }
                            </button>
                        </>

                        : <button onClick={closeAddKeyModal} className='button button-danger'>Close</button>
                    }
                </Modal.Footer>
            </Modal>


            <Modal show={deleteKeyModal} onHide={() => setDeleteKeyModal(false)} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Access Key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='fw-semibold'>Are you sure you want to delete this access key?</p>
                    <p className='m-1'><span className='text-secondary'>Name: </span>{selectedKey?.access_key_name}</p>
                    <p className='m-1'><span className='text-secondary'>Key: </span>{selectedKey?.access_key}</p>
                    <p className='m-1'><span className='text-secondary'>Expires at: </span>{formatDate(new Date(selectedKey?.expires_at))}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={deletingKey} onClick={() => deleteKey(selectedKey?.access_key_id, selectedKey?.access_key_name)} className='button button-danger'>
                        {deletingKey
                            ?
                            <>
                                Yes, delete
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : "Yes, delete"
                        }
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal show={updateKeyModal} onHide={() => setUpdateKeyModal(false)} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Update Access Key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='container'>
                            <div className='input-grp my-3'>
                                <label htmlFor="key-name">Key Name <span className='text-danger'>*</span></label>
                                <input type="text" id="key-name" value={selectedKey?.access_key_name} onChange={e => { setError(""); setSelectedKey(prev => { return { ...prev, access_key_name: e.target.value } }) }} />
                            </div>
                            <div className='input-grp my-3'>
                                <label htmlFor="key-expiry">Expires at <span className='text-danger'>*</span></label>
                                <input type="datetime-local" id="key-expiry" value={selectedKey?.expires_at ? formatLocalDate(new Date(selectedKey.expires_at)) : ""} onChange={e => { setError(""); setSelectedKey(prev => { return { ...prev, expires_at: e.target.value } }) }} />
                            </div>
                            <p className='text-danger fs-7 my-2 mx-2' style={{ fontSize: '15px' }}>{error}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={updatingKey} onClick={() => updateKey(selectedKey)} className='button'>
                        {updatingKey
                            ?
                            <>
                                Update
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : "Update"
                        }
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default AccessKeys