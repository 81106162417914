import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../context/UserProvider';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import axios from 'axios';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast'
import BedrockApiKeysSetting from './BedrockApiKeysSetting';


const AddBedrockAPIkeyModal = (props) => {

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='px-4'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.heading ? props.heading : 'API keys not found'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 pt-5 pb-0'>
                <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} />
                <BedrockApiKeysSetting
                    show={props.show}
                    onHide={props.onHide}
                    handleSubmit={props.handleSubmit}
                />
            </Modal.Body>

            <Modal.Footer className='mt-2'>
                <button className='button button-danger' onClick={() => props.onHide()}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddBedrockAPIkeyModal