import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/logo.svg'

const Footer = () => {
  return (
    <footer id="footer">
      <div className='footer-links'>
        <a href="https://sagecollab.com/category/updates/" rel="noreferrer" target="_blank">Updates</a>
        <a href="https://sagecollab.com/privacy-policy/"  rel="noreferrer"target="_blank">Privacy Policy</a>
        <a href="https://sagecollab.com/terms-of-service/" rel="noreferrer" target="_blank">Terms of Service</a>
      </div>
      <a href="https://sagecollab.com" target="_blank"  rel="noreferrer" className="home-branding responsive px-3">
        <img
          alt="logo"
          src={logo}
          className="home-logo"
        />
        <span className="home-company" style={{ color: "#fff", fontWeight: "600" }}>SageCollab</span>
      </a>
    </footer>
  )
}

export default Footer