import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Axios from '../api/axios'
import { UserContext } from '../context/UserProvider';
import RegistrationSuccess from './RegistrationSuccess';
import { toast } from 'react-hot-toast'
import { AiFillTags, AiOutlineTags, AiOutlineUserAdd } from 'react-icons/ai'
import { CgNotes } from 'react-icons/cg'
import { GoogleLogin } from '@react-oauth/google';

const RegistrationForm = () => {
    const [user, setUser] = useContext(UserContext)
    const [registrationSucceeded, setRegistrationSucceeded] = useState(false)
    const [registrationData, setRegistrationData] = useState({
        name: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: ''
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const [usingGoogleSignin, setUsingGoogleSignin] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);
    const redirectTo = urlParams.get('redirectTo') || false;

    const register = e => {
        e.preventDefault()
        setLoading(true)
        setError('')

        if (registrationData.password !== registrationData.confirmPassword) {
            setError('Please add the same password in both fields')
            setLoading(false)
        }
        else if (registrationData.username.length < 3 || registrationData.username.length > 15) {
            setError('Username should be between 3 to 15 characters')
            setLoading(false)
        }
        else {
            Axios.post('/auth/register', registrationData)
                .then(res => {
                    setLoading(false)
                    if (res.data) {
                        setUser(res.data)

                        if (redirectTo) {
                            navigate(redirectTo)
                        } else {
                            navigate('/')
                        }

                        toast('You are successfully registered with Free plan', {
                            icon: "👍"
                        })
                        setRegistrationSucceeded(true)
                    } else {
                        toast.error('Could not register you at the moment, please try again later')
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    }
                    if (err.response.status === 400) {
                        setError(err.response.data.error)
                    }
                })
        }

    }

    const googleSignin = credential => {
        setUsingGoogleSignin(true)
        Axios.post("/auth/googleSignin", { credential })
            .then(res => {
                setUser(res.data)
                if (redirectTo) {
                    navigate(redirectTo)
                } else {
                    navigate('/')
                }
                toast.success('Signin successful')
                setUsingGoogleSignin(false)
            })
            .catch(err => {
                setUsingGoogleSignin(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    return (
        <div className='form lg'>
            {!registrationSucceeded
                ?
                <>
                    <h2>Join SageCollab</h2>
                    {/* <p className='subtitle text-center' style={{ marginTop: '-1rem' }}>Using ChatGPT? Save all chats, add your notes, categorize and search your chat history.</p> */}
                    <p className="text-center px-md-5">Empower AI chats and knowledge management. Store, categorize, and search AI chat history; enrich with personalized notes, foster team collaboration, and harness the potential of prompt templates.</p>
                    <h2 className="font-xs fw-medium text-center">Sign up now and start using these prompt templates with your AI chat.</h2>


                    {redirectTo &&
                        redirectTo?.includes("/chat/shared/continue/")
                        &&
                        <div class="alert alert-primary font-xxs w-100 text-center">
                            Register to continue the shared chat
                        </div>
                    }

                    {redirectTo &&
                        redirectTo?.includes("/marketplace/template/")
                        &&
                        <div class="alert alert-primary font-xxs w-100 text-center">
                            Register on SageCollab to use templates.
                        </div>
                    }

                    {redirectTo &&
                        redirectTo?.includes("/start-chat?templateId=")
                        &&
                        <div class="alert alert-primary font-xxs w-100 text-center">
                            Register to start chat with template.
                        </div>
                    }


                    <div className='mt-3 d-flex flex-column align-items-center gap-3'>
                        {usingGoogleSignin
                            ?
                            <div style={{ width: "227px", height: "44px", padding: "2px 10px" }}>
                                <div className='border w-100 h-100 d-flex align-items-center justify-content-center'>
                                    <div className="dot-wrapper">
                                        <div className="dot dot1"></div>
                                        <div className="dot dot2"></div>
                                        <div className="dot dot3"></div>
                                        <div className="dot dot4"></div>
                                    </div>
                                </div>
                            </div>

                            :
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    googleSignin(credentialResponse.credential);
                                }}
                                onError={() => {
                                    toast.error("Could not log you in at the moment")
                                }}
                            // useOneTap={true}
                            />
                        }

                    </div>

                    <div className='custom-divider'>
                        <span />
                        <p>or</p>
                        <span />
                    </div>

                    <form className='form-container' onSubmit={register}>
                        <div className='input-grp'>
                            <label htmlFor="full-name-input">Full Name <span className='text-danger'>*</span></label>
                            <input type="text" required id="full-name-input" value={registrationData.name} onChange={e => setRegistrationData({ ...registrationData, name: e.target.value })} />
                        </div>
                        <div className='form-grp'>
                            <div className='input-grp'>
                                <label htmlFor="email-input">Email <span className='text-danger'>*</span></label>
                                <input type="email" required id="email-input" value={registrationData.email} onChange={e => setRegistrationData({ ...registrationData, email: e.target.value })} />
                            </div>
                            <div className='input-grp'>
                                <label htmlFor="username-input">Username <span className='text-danger'>*</span></label>
                                <input type="text" minLength={3} maxLength={15} required id="username-input" value={registrationData.username} onChange={e => setRegistrationData({ ...registrationData, username: e.target.value })} />
                            </div>
                        </div>


                        <div className='form-grp'>
                            <div className='input-grp'>
                                <label htmlFor="password-input">Password <span className='text-danger'>*</span></label>
                                <input type='password' id="password-input" required value={registrationData.password} onChange={e => setRegistrationData({ ...registrationData, password: e.target.value })} />
                            </div>
                            <div className='input-grp'>
                                <label htmlFor="confirm-password-input">Confirm Password <span className='text-danger'>*</span></label>
                                <input type='password' id="confirm-password-input" required value={registrationData.confirmPassword} onChange={e => setRegistrationData({ ...registrationData, confirmPassword: e.target.value })} />
                            </div>
                        </div>

                        <div className='free-plan-features'>
                            <div className='note'>You will be registered to <span style={{ fontWeight: "500" }}>Free plan</span> with features like:</div>
                            <div className='free-plan-feature-list'>
                                <div className='free-plan-feature'>
                                    <AiOutlineTags />
                                    Tag your chats
                                </div>
                                <div className='free-plan-feature'>
                                    <CgNotes />
                                    Add notes to chat
                                </div>
                                <div className='free-plan-feature'>
                                    <AiOutlineUserAdd className='f-plus' />
                                    Share chat with others
                                </div>
                            </div>
                        </div>

                        <p className='mb-0 conditions'>By creating an account, you are agreeing to our <a href="https://sagecollab.com/terms-of-service/" target='_blank'>Terms of Service</a> and <a href="https://sagecollab.com/privacy-policy/" target='_blank'>Privacy Policy</a>. You also agree to receive product-related marketing emails from us, which you can unsubscribe from at any time.</p>

                        <div className='btn-container mb-3' style={{ marginTop: "-1rem" }}>
                            <p className='subtitle text-danger'>{error}</p>
                            <div className='w-mobile-only-full'>
                                <button type="submit" className="button button-main w-100" disabled={loading}>
                                    {loading
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        : "Register"
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </>

                : <RegistrationSuccess />
            }
        </div>
    )
}

export default RegistrationForm