import React, { useContext, useEffect, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import UpdateProfileForm from '../components/UpdateProfileForm'
import { toast } from 'react-hot-toast'
import { Helmet } from 'react-helmet'

const Profile = () => {
  const [user, setUser] = useContext(UserContext)
  const [profile, setProfile] = useState('')
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [refreshProfile, setRefreshProfile] = useState(false)
  const [newProfilePicture, setNewProfilePicture] = useState('')
  const [updatingProfilePicture, setUpdatingProfilePicture] = useState(false)

  useEffect(() => {
    fetchProfile()
  }, [])

  const fetchProfile = () => {
    setLoadingProfile(true)
    ProtectedAxios.post('/users/myDetails', { user_id: user.user_id })
      .then(res => {
        setProfile(res.data)
        setLoadingProfile(false)
      })
      .catch(err => {
        setLoadingProfile(false)
        toast.error(err.response.data.error)
      })
  }

  const handleProfilePictureChange = e => {
    if ((e.target.files[0].size / (1024 * 1024)).toFixed(2) > 5) {
      toast('File size should be less then 5MB', {
        icon: "⚠️"
      })
    } else {
      setNewProfilePicture(e.target.files[0])
    }
  }

  const changeProfilePicture = async (e) => {
    e.preventDefault()

    setUpdatingProfilePicture(true)

    const formData = new FormData()
    formData.append('newProfilePicture', newProfilePicture)
    formData.append('user_id', user.user_id)

    ProtectedAxios.post('/users/changeProfilePicture', formData)
      .then(res => {
        setProfile(res.data)
        setNewProfilePicture('')
        setUser({ ...user, profile_picture_src: res.data.profile_picture_src })
        toast.success("Profile picture updated")
        setUpdatingProfilePicture(false)
      })
      .catch(err => {
        toast.error(err.response.data.error)
        setUpdatingProfilePicture(false)
      })
  }

  return (
    <div className='container py-5'>
      <Helmet>
        <title>Profile - SageCollab</title>
      </Helmet>
      <div className='profile-container'>
        <div className='profile-picture' style={{ backgroundImage: `url(${newProfilePicture ? URL.createObjectURL(newProfilePicture) : user.profile_picture_src})` }} />
        <form onSubmit={changeProfilePicture}>
          <div className='edit-btn-container'>
            {!newProfilePicture
              ?
              <input type='file' accept="image/*" className='upload-new-profile-input' onChange={e => handleProfilePictureChange(e)} max-size="5000000" />

              :
              !updatingProfilePicture
                ?
                <>
                  Change
                  <button className='edit-btn' title='update profile picture' onClick={(e) => { }}><RiCheckLine className='edit-icon accept-icon ' /></button>

                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  Cancel
                  <button className='edit-btn' title='cancel' onClick={() => { setNewProfilePicture('') }}><RiCloseLine className='edit-icon reject-icon ' /></button>
                </>

                :
                <div className='mt-3'>
                  Updating
                  <div className="mx-2 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
            }
          </div>
        </form>
      </div>

      <UpdateProfileForm />
    </div>
  )
}

export default Profile