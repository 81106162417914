import React, { useContext, useEffect, useRef, useState } from 'react'
import Table from 'react-bootstrap/Table';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaFilter } from 'react-icons/fa'
import ManageUserTable_SubscriptionDetails from '../components/ManageUserTable_SubscriptionDetails';
import { RiCoinsFill } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import { MdDone, MdMail } from 'react-icons/md';
import TokenIcon from '../components/custom-svgs/TokenIcon';
import { formatDate, formatLocalDate } from '../utils/helper';
import { BsGoogle } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const ManageUsers = () => {
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [refreshUserList, setRefreshUserList] = useState(false)

  const [selectedUser, setSelectedUser] = useState(null)
  const [deletingUser, setDeletingUser] = useState(false)


  const [selectedPlanFilter, setSelectedPlanFilter] = useState('')

  const [additionalTokens, setAdditionalTokens] = useState('')
  const [addingTokens, setAddingTokens] = useState(false)
  const [tokensUpdated, setTokensUpdated] = useState(false)

  const [show, setShow] = useState(false)
  const hideAddTokensModel = () => setShow(false)
  const showAddTokensModel = () => setShow(true)

  let isMounted = false

  useEffect(() => {
    if (!isMounted) {
      isMounted = true
      fetchAllUsers()
    }
  }, [refreshUserList])

  const fetchAllUsers = () => {
    setLoadingUsers(true)
    ProtectedAxios.get('/admin/allUsers')
      .then(res => {
        setUsers(res.data)
        setFilteredUsers(res.data)
        setLoadingUsers(false)
      })
      .catch(err => {
        toast.error(err.response.data.error)
        setLoadingUsers(false)
      })
  }

  useEffect(() => {
    filterUsersByPlan()
  }, [selectedPlanFilter])
  const filterUsersByPlan = () => {
    if (selectedPlanFilter !== "") {
      setLoadingUsers(true)
      if (selectedPlanFilter === "All") {
        setFilteredUsers(users)
        setLoadingUsers(false)
      }
      else if (selectedPlanFilter === "Free") {
        let filteredUsers = []
        users.forEach((user) => {
          if (user.user_plan === "Free") {
            filteredUsers.push(user)
          }
        })
        setFilteredUsers(filteredUsers)
        setLoadingUsers(false)
      }
      else if (selectedPlanFilter === "Standard") {
        let filteredUsers = []
        users.forEach((user) => {
          if (user.user_plan === "Standard") {
            filteredUsers.push(user)
          }
        })
        setFilteredUsers(filteredUsers)
        setLoadingUsers(false)
      }
      else if (selectedPlanFilter === "Enterprise") {
        let filteredUsers = []
        users.forEach((user) => {
          if (user.user_plan === "Enterprise") {
            filteredUsers.push(user)
          }
        })
        setFilteredUsers(filteredUsers)
        setLoadingUsers(false)
      }
      else if (selectedPlanFilter === "Enterprise Child") {
        let filteredUsers = []
        users.forEach((user) => {
          if (user.user_plan === "Enterprise Child") {
            filteredUsers.push(user)
          }
        })
        setFilteredUsers(filteredUsers)
        setLoadingUsers(false)
      }
    }
  }


  useEffect(() => {
    if (tokensUpdated) {
      setTimeout(() => {
        setTokensUpdated(false)
      }, 2000)
    }
  }, [tokensUpdated])

  const addTokens = async (e) => {
    e.preventDefault()
    setAddingTokens(true)
    ProtectedAxios.post('/admin/addTokens', { currentToken: selectedUser?.tokens, additionalTokens, selectedUserId: selectedUser?.user_id })
      .then(res => {
        if (res.data) {
          setSelectedUser(prev => {
            return { ...prev, tokens: res.data.updatedTokens }
          })
          setFilteredUsers(prevUsers => {

            const updatedUsers = prevUsers.map(user => {
              if (user.user_id === selectedUser.user_id) {
                return { ...user, tokens: res.data.updatedTokens }
              }
              return user
            })

            return updatedUsers
          })
        }
        setAddingTokens(false)
        setTokensUpdated(true)
        setAdditionalTokens("")
      })
      .catch(err => {
        toast.error(err.response.data.error)
        setAddingTokens(false)
      })

  }

  return (
    <div className='container py-5'>
      <Helmet>
        <title>Users - SageCollab</title>
      </Helmet>

      <h2>Manage Users</h2>
      <p className='subtitle'>Note: Disabling a user account will prevent users from logging in and accessing their dashboard</p>
      <div className='table-container overflow-x-scroll pb-5' style={{ minHeight: '16rem' }}>
        <Table className='my-table' striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email <br />(Signin Method)</th>
              <th>Regn. date</th>
              <th>Chats</th>
              <th>Shared Chats</th>
              <th>Tokens</th>
              <th>
                <div className='centered-flex gap-2'>
                  Plan
                  <Dropdown className={`dropdown-link ${loadingUsers ? 'pe-none opacity-25 cursor-not-allowed' : ''}`}>
                    <Dropdown.Toggle>
                      <button className='edit-btn dark-btn' title='filter' ><FaFilter className='edit-icon reject-icon filter-icon' /></button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <a className={`dropdown-item cursor-pointer ${selectedPlanFilter === "All" ? 'active' : ''}`} onClick={() => setSelectedPlanFilter("All")}>All</a>
                      <a className={`dropdown-item cursor-pointer ${selectedPlanFilter === "Free" ? 'active' : ''}`} onClick={() => setSelectedPlanFilter("Free")}>Free</a>
                      <a className={`dropdown-item cursor-pointer ${selectedPlanFilter === "Standard" ? 'active' : ''}`} onClick={() => setSelectedPlanFilter("Standard")}>Standard</a>
                      <a className={`dropdown-item cursor-pointer ${selectedPlanFilter === "Enterprise" ? 'active' : ''}`} onClick={() => setSelectedPlanFilter("Enterprise")}>Enterprise</a>
                      <a className={`dropdown-item cursor-pointer ${selectedPlanFilter === "Enterprise Child" ? 'active' : ''}`} onClick={() => setSelectedPlanFilter("Enterprise Child")}>Enterprise Child</a>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </th>
              {/* <th>Plan</th> */}
              <th>Status</th>
              <th>Template Creator</th>
              <th>Keys</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadingUsers
              ?
              <tr>
                <td colSpan={13} className='text-center p-5'>
                  <div className="spinner-border spinner-border-md" role="status">
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>

              :
              <>
                {filteredUsers.length > 0
                  ?
                  <>
                    {filteredUsers.map((user, i) => {
                      return (
                        <tr key={i}
                          className={`user-table-row ${!user.is_active ? 'disabled-account-row' : ''} ${(selectedUser?.user_id === user.user_id && deletingUser) ? 'deleting-key' : ''}`}
                        >
                          <td>{i + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.username}</td>
                          <td>
                            {user.email}
                            <br />
                            <span>
                              {user.signin_provider === "EMAIL"
                                ?
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      Email for signin
                                    </Tooltip>
                                  )}
                                  placement="right"
                                >
                                  <button type='button' className='edit-btn'><MdMail /></button>
                                </OverlayTrigger>

                                : user.signin_provider === "GOOGLE"
                                  ? <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        Google for signin
                                      </Tooltip>
                                    )}
                                    placement="right"
                                  >
                                    <button type='button' className='edit-btn'><BsGoogle /></button>
                                  </OverlayTrigger>

                                  : ""
                              }
                            </span>
                          </td>
                          <td>{formatDate(new Date(user.registration_timestamp))}</td>
                          <td>{user.total_chats}</td>
                          <td>{user.total_shared_chats}</td>
                          <td>
                            <div className='token-container token-button' onClick={() => { setSelectedUser(user); showAddTokensModel() }}>
                              <TokenIcon fill='var(--color-golden)' />
                              <u>
                                <p>
                                  {parseInt(user.tokens).toLocaleString()}
                                </p>
                              </u>
                            </div>
                          </td>
                          <ManageUserTable_SubscriptionDetails customer_id={user.stripe_customer_id} user_details={user} fetchAllUsers={fetchAllUsers} setUsers={setUsers} />
                        </tr>
                      )
                    })}
                  </>


                  :
                  <tr>
                    <td colSpan={13} className='text-center p-5 text-danger'>no users found</td>
                  </tr>
                }
              </>
            }


          </tbody>
        </Table>
      </div>

      <Modal show={show} onHide={hideAddTokensModel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Tokens</Modal.Title>
        </Modal.Header>
        <form onSubmit={addTokens}>
          <Modal.Body>
            <div className='container'>
              <p className='mb-2'>Update tokens for user - <b>{selectedUser?.username}</b></p>
              <p className='d-flex gap-2'>Available Tokens -
                <div className='token-container token-button'>
                  <TokenIcon fill='var(--color-golden)' />
                  <p>
                    {parseInt(selectedUser?.tokens).toLocaleString()}
                  </p>
                  {tokensUpdated
                    &&
                    <span style={{ fontSize: "var(--fs-5)", color: "green" }}><MdDone style={{ fill: "green", width: "var(--fs-4)" }} />Updated</span>
                  }
                </div>
              </p>
              <div className='input-grp'>
                <label htmlFor="username-email">Add Tokens <span className='text-danger'>*</span></label>
                <input autoFocus onFocus={e => e.target.select()} type="number" id="username-email" value={additionalTokens} onChange={e => setAdditionalTokens(e.target.value)} />
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className='button button-danger' onClick={hideAddTokensModel}>cancel</button>
            <button type='submit' className='button' disabled={addingTokens}>
              {addingTokens
                ?
                <>
                  Add
                  <div className="mx-2 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                  </div>
                </>

                :
                "Add"
              }
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div >
  )
}

export default ManageUsers